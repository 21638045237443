const mayaExitSessionMiddleware = (config?: any) => (
  state: any,
  action: any,
  uneeq: any,
  context: any
) => {
  if (action?.type === 'mayaMessage' && action?.payload?.type === 'exit') {
    const { endSession } = context

    // We need the timeout because we have a race condition on the reducers.
    // Once we change to redux this will be unnecessary
    setTimeout(() => {
      config.endMayaSession()
      endSession()
    })
  }
}

export default mayaExitSessionMiddleware
