import { useMemo } from 'react'
import { useUneeqState } from 'uneeq-react-core'
import { sendMessage } from '../../socket'

export const useSendSocketMessage = () => {
  const state = useUneeqState()
  const currentSession = state?.session
  const sendMessageWithSession = useMemo(() => sendMessage(currentSession), [
    currentSession
  ])

  return sendMessageWithSession
}
