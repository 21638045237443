import React, { useContext, useRef, useEffect, useState } from 'react'
import { Box, Flex, Image, Text } from 'rebass'
import { UneeqContext, useIsSmallScreen, useUneeqState } from 'uneeq-react-core'
import { Button } from 'rebass'
import {
  PermissionsPrompt,
  PermissionsRejected,
  AvatarUnavailable
} from 'uneeq-react-ui'
import styles from './styles'
import { motion, AnimatePresence } from 'framer-motion'
import LoadingVideo from '../LoadingVideo/LoadingVideo'
import { useCallback } from 'react'
import { MayaOverlay } from '../MayaOverlay'
import { useTvContext } from '../../app/hooks/useTvContext'

const LoaderVideo = ({ loaded, done, isSmallScreen, widgetMode }: any) => {
  const buttonStyle = isSmallScreen
    ? {
        position: 'absolute',
        zIndex: 5,
        top: 10,
        right: 10,
        bottom: 'auto'
      }
    : {
        position: 'absolute',
        right: 15,
        bottom: 15,
        zIndex: 5,
        top: 'auto'
      }

  return (
    <Box sx={widgetMode ? styles.loaderVideoWidget : styles.loaderVideo}>
      <LoadingVideo onFinishVideo={done} widgetMode={widgetMode} />
      {loaded ? (
        <Button id="skip" onClick={done} sx={buttonStyle}>
          Skip
        </Button>
      ) : (
        <Button id="loading" disabled sx={buttonStyle}>
          Loading...
        </Button>
      )}
    </Box>
  )
}

const MotionText = motion.custom(Text)
export interface LoadingTip {
  title: string
  videoWebm?: any
  videoMP4?: any
  img?: any
  showOnDesktop: boolean
  showOnMobile: boolean
}
interface LoadingProps {
  loadingTips: Array<LoadingTip>
  isSmallScreen: boolean
  widgetMode?: boolean
}

export const Loading: React.FC<LoadingProps> = ({
  loadingTips = [
    {
      title:
        'Maya is a digital health assistant that can help you collaborate with a clinician.',
      showOnMobile: true,
      showOnDesktop: true
    },
    {
      title:
        'If you think you may have a medical emergency, call your doctor or 911 immediately.',
      showOnMobile: true,
      showOnDesktop: true
    },
    {
      title: 'MayaMD combines the power of AI with the best medical expertise',
      showOnMobile: true,
      showOnDesktop: true
    }
  ],
  isSmallScreen,
  widgetMode
}) => {
  let checkedLoadingTips = loadingTips

  const { loadingPercentage } = useUneeqState()

  if (isSmallScreen) {
    checkedLoadingTips = checkedLoadingTips.filter(tip => tip.showOnMobile)
  }

  const [currentLoadingTip, setCurrentLoadingTip] = useState(
    checkedLoadingTips?.[0]
  )
  const [showLoadingTipText, setShowLoadingTipText] = useState(true)

  useEffect(() => {
    if (checkedLoadingTips.length > 0) {
      const changeLoadingTip = () => {
        setShowLoadingTipText(false)

        // When the exit animation finishes we show the new tip.
        setTimeout(() => {
          const currIdx = checkedLoadingTips.findIndex(
            lt => lt?.title === currentLoadingTip?.title
          )
          if (currIdx === checkedLoadingTips.length - 1) {
            setCurrentLoadingTip(checkedLoadingTips[0])
          } else {
            setCurrentLoadingTip(checkedLoadingTips[currIdx + 1])
          }
          setShowLoadingTipText(true)
        }, 600)
      }

      const timeout = setTimeout(changeLoadingTip, 2800)

      return () => clearTimeout(timeout)
    }
  }, [checkedLoadingTips, currentLoadingTip])

  return (
    <MayaOverlay sx={{ backgroundColor: 'black' }}>
      <Flex sx={styles.loading.container}>
        <Flex
          sx={styles.loading.videoContainer}
          id="videoContainer"
          data-testid="videoContainer"
        >
          {checkedLoadingTips.length > 0 && (
            <AnimatePresence>
              {showLoadingTipText && (
                <MotionText
                  variants={{
                    start: {
                      opacity: 0,
                      transform: 'rotateX(-100deg)',
                      transformOrigin: 'top'
                      // transition: {duration: 0.6, ease: 'easeIn'},
                    },
                    animate: {
                      opacity: 1,
                      transform: 'rotateX(0deg)',
                      transformOrigin: 'top'
                    },
                    exit: {
                      opacity: 0,
                      transform: 'rotateX(100deg)',
                      transformOrigin: 'bottom',
                      transition: { duration: 0.6, ease: 'easeOut' }
                    }
                  }}
                  initial="start"
                  animate="animate"
                  exit="exit"
                  transition={{ duration: 0.6, ease: 'easeIn' }}
                  key={currentLoadingTip?.title}
                  sx={styles.loading.message(widgetMode)}
                >
                  {currentLoadingTip?.title}
                </MotionText>
              )}
            </AnimatePresence>
          )}
          <Flex sx={styles.loading.barContainer(widgetMode)}>
            <Flex sx={styles.loading.barInnerContainer(widgetMode)}>
              <Flex
                sx={{
                  ...styles.loading.bar,
                  width: `${loadingPercentage * 5}px`
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </MayaOverlay>
  )
}

const LoadingScreen = React.memo((props: any) => {
  const { tvAppMode } = useTvContext()
  return tvAppMode ? <Loading {...props} /> : <LoaderVideo {...props} />
})
interface GatewayProps {
  restart: () => void
  children: any
  video?: any
  widgetMode?: boolean
  showLoadingVideo: boolean
}
const Gateway: React.FC<GatewayProps> = ({
  restart,
  children,
  video,
  widgetMode = false,
  showLoadingVideo
}) => {
  const {
    ready,
    unavailable,
    permissionAllowed,
    sessionEnded,
    webRTCSignalReceived
  } = useUneeqState()
  const { config, playDHVideo, repeatLastQuestion, dispatch } = useContext(
    UneeqContext
  )
  const { tvAppMode } = useTvContext()
  const isSmallScreen = useIsSmallScreen()
  const [showLoading, setShowLoading] = useState(showLoadingVideo)

  const getShowChildren = () => {
    if (tvAppMode) {
      return ready || sessionEnded
    }

    return (ready && !showLoading) || sessionEnded
  }

  const handleDoneVideo = useCallback(() => {
    setShowLoading(false)
    dispatch({ type: 'loadingVideoFinished' })

    // iOS devices pauses DH video while introduction is being reproduced.
    // We have to resume as soon as the introduction video finishes
    playDHVideo()
    repeatLastQuestion()
  }, [playDHVideo, repeatLastQuestion, dispatch])

  useEffect(() => {
    dispatch({ type: 'loadingVideoActive' })
  }, [dispatch])

  useEffect(() => {
    if (!showLoadingVideo) {
      dispatch({ type: 'loadingVideoFinished' })
    }
  }, [dispatch, showLoadingVideo])

  useEffect(() => {
    // Tv app mode doesn't have a video so we have to trigger the action after uneeq finishes loading
    if (tvAppMode && webRTCSignalReceived && ready && showLoading)
      handleDoneVideo()
  }, [webRTCSignalReceived, ready, handleDoneVideo, showLoading, tvAppMode])

  if (unavailable) return <AvatarUnavailable restart={restart} />

  if (config.sendLocalVideo || config.sendLocalAudio) {
    return permissionAllowed === null ? (
      <PermissionsPrompt video={video} />
    ) : permissionAllowed === false ? (
      <PermissionsRejected restart={restart} />
    ) : permissionAllowed && ready && !showLoading ? (
      children
    ) : (
      // UneeQ is not ready or
      // Permissions are completely unknown
      // They could be aproved, rejected or neither
      // Show loader for now..
      <LoadingScreen
        isSmallScreen={isSmallScreen}
        loaded={permissionAllowed && ready}
        done={handleDoneVideo}
        widgetMode={widgetMode}
      />
    )
  } else {
    return getShowChildren() ? (
      children
    ) : (
      <LoadingScreen
        isSmallScreen={isSmallScreen}
        loaded={ready}
        done={handleDoneVideo}
        widgetMode={widgetMode}
      />
    )
  }
}

export default Gateway
