import { useContext, useMemo } from 'react'
import { UneeqContext } from 'uneeq-react-core'
import useSocketEventHandler from '../../app/hooks/useSocketEventHandler'

const useMayaConnect = () => {
  const { dispatch } = useContext(UneeqContext)
  const handler = useMemo(() => handlerEvents(dispatch), [dispatch])
  useSocketEventHandler(handler)
}

const handlerEvents = dispatch => (event, payload) => {
  if (event === 'socketConnected') {
    dispatch({ type: 'socketConnected' })
  }

  console.log('EVENT', event, 'PAYLOAD', payload)
  if (event === 'message') {
    if (!payload) return null
    if (payload?.type === 'error') {
      dispatch({ type: 'mayaError', payload })
    } else {
      dispatch({ type: 'mayaMessage', payload })
    }
  }

  if (process.env.NODE_ENV === 'development') {
    if (event === 'connect_error') {
      console.error('WS CONNECT FAILED:', payload)
    }

    if (event === 'connect_timeout') {
      console.error('WS CONNECTION TIMED OUT:', payload)
    }

    if (event === 'error') {
      console.error('WS ERROR:', payload)
      if ('Unauthorized' === payload) {
        console.error(payload)
      }
    }

    if (event === 'disconnect') {
      console.error('WS DISCONNECTED:', payload)
    }

    if (event === 'reconnect') {
      console.error('WS RECONNECTED:', payload)
    }
    if (event === 'reconnecting') {
      console.error('WS RECONNECTING:', payload)
    }
    if (event === 'reconnect_error') {
      console.error('WS RECONNECT ERROR:', payload)
    }
    if (event === 'reconnect_failed') {
      console.error('WS RECONNECT FAILED')
    }
  }
}

export default useMayaConnect
