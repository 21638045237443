import { useTheme } from 'emotion-theming'
import React from 'react'

interface ErrorProps {
  outsideBorderColor: string
  insideBorderColor: string
}

const Error = () => {
  const theme: any = useTheme()

  const outsideBorderColor = theme.colors.svgIconColor1 || '#4287f5'
  const insideBorderColor = theme.colors.svgIconColor2 || '#4287f5'
  return (
    <svg
      width="57"
      height="52"
      viewBox="0 0 57 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.5 2.70001C26.4505 2.70001 24.4861 3.81488 23.4682 5.59615L23.4658 5.60046L13.4658 22.9005C13.1894 23.3786 12.5777 23.5422 12.0996 23.2658C11.6214 22.9894 11.4578 22.3777 11.7342 21.8996L21.7318 4.60387L21.7331 4.60155C23.1156 2.1842 25.7503 0.700012 28.5 0.700012C31.2498 0.700012 33.8847 2.18437 35.2672 4.60197L35.2682 4.60387L55.462 39.5931C56.1626 40.7642 56.5 42.1431 56.5 43.5C56.5 47.7427 53.0618 51.3 48.7 51.3H8.3C4.05731 51.3 0.5 47.8618 0.5 43.5C0.5 42.1429 0.837463 40.764 1.53818 39.5927L10.0343 24.8994C10.3108 24.4213 10.9225 24.2579 11.4006 24.5343C11.8787 24.8108 12.0422 25.4225 11.7657 25.9006L3.2657 40.6006L3.25749 40.6145C2.76115 41.4417 2.5 42.4599 2.5 43.5C2.5 46.7382 5.14269 49.3 8.3 49.3H48.7C51.9382 49.3 54.5 46.6573 54.5 43.5C54.5 42.4599 54.2388 41.4417 53.7425 40.6145L53.7339 40.5999L33.5339 5.59988L33.5318 5.59615C32.5139 3.81488 30.5495 2.70001 28.5 2.70001Z"
        fill={outsideBorderColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28.4998 6.60004C27.7279 6.60004 27.1812 6.93267 26.874 7.48568L26.8659 7.4999L6.67038 42.4922C6.34176 43.0889 6.34295 43.8186 6.67396 44.4144C6.681 44.4271 6.68775 44.4399 6.69423 44.4528C7.0098 45.084 7.54373 45.4 8.2998 45.4H30.3998C30.9521 45.4 31.3998 45.8478 31.3998 46.4C31.3998 46.9523 30.9521 47.4 30.3998 47.4H8.2998C6.86269 47.4 5.60175 46.7225 4.91509 45.3666C4.25788 44.1681 4.26139 42.71 4.92565 41.5144L4.9337 41.5002L25.1299 6.50677C25.8235 5.26468 27.0746 4.60004 28.4998 4.60004C29.9337 4.60004 31.1922 5.27446 31.8799 6.62434L52.0659 41.6002L52.074 41.6144C52.7418 42.8164 52.7418 44.2837 52.074 45.4857C51.4041 46.6915 50.0761 47.5 48.6998 47.5H33.7998C33.2475 47.5 32.7998 47.0523 32.7998 46.5C32.7998 45.9478 33.2475 45.5 33.7998 45.5H48.6998C49.3235 45.5 49.9955 45.1086 50.3256 44.5144C50.6567 43.9186 50.6579 43.189 50.3293 42.5922L30.1337 7.5999C30.1237 7.58264 30.1143 7.56508 30.1054 7.54725C29.7898 6.91612 29.2559 6.60004 28.4998 6.60004Z"
        fill={insideBorderColor}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.0933 17.0929C25.5336 16.6525 26.1752 16.3 26.9004 16.3H30.0004C30.7256 16.3 31.3672 16.6525 31.8075 17.0929C32.3612 17.6466 32.5004 18.3458 32.5004 18.9C32.5004 18.921 32.4997 18.942 32.4984 18.9629L31.7984 30.0629C31.7971 30.0833 31.7952 30.1037 31.7927 30.124C31.6386 31.3563 30.6705 32.4 29.3004 32.4H27.6004C26.3503 32.4 25.1208 31.4556 25.1006 30.0355L24.4024 18.9629C24.4011 18.942 24.4004 18.921 24.4004 18.9C24.4004 18.3458 24.5396 17.6466 25.0933 17.0929ZM26.9004 18.3C26.8256 18.3 26.6672 18.3474 26.5075 18.5071C26.4629 18.5517 26.405 18.6466 26.4006 18.8726L27.0984 29.937C27.0997 29.958 27.1004 29.979 27.1004 30C27.1004 30.0938 27.1377 30.1825 27.2231 30.26C27.3134 30.3422 27.4484 30.4 27.6004 30.4H29.3004C29.525 30.4 29.7515 30.2507 29.8046 29.901L30.5001 18.8726C30.4958 18.6466 30.4378 18.5517 30.3933 18.5071C30.2336 18.3474 30.0752 18.3 30.0004 18.3H26.9004Z"
        fill="currentcolor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.7004 37.1C24.7004 34.9246 26.4715 33.3 28.5004 33.3C30.5527 33.3 32.3004 35.0477 32.3004 37.1C32.3004 39.2754 30.5293 40.9 28.5004 40.9C26.3481 40.9 24.7004 39.2523 24.7004 37.1ZM28.5004 35.3C27.5293 35.3 26.7004 36.0754 26.7004 37.1C26.7004 38.1477 27.4527 38.9 28.5004 38.9C29.4715 38.9 30.3004 38.1246 30.3004 37.1C30.3004 36.1523 29.4481 35.3 28.5004 35.3Z"
        fill="currentcolor"
      />
    </svg>
  )
}
export default Error
