import React, { useContext } from 'react'
import { UneeqContext, useUneeqError } from 'uneeq-react-core'
import { Warning } from 'uneeq-react-ui'
import FatalError from './FatalError'
import Error from './Error'

const MayaErrors = () => {
  const error = useUneeqError()
  const { dispatch, restartSession } = useContext(UneeqContext)
  const clearError = () => {
    dispatch({ type: 'clearError' })
    restartSession()
  }

  switch (error?.level) {
    case 'warning':
      return <Warning message={error.message} />
    case 'error':
      return <Error message={error.message} />
    case 'fatal':
      return <FatalError errorMessage={error.message} clearError={clearError} />
    default:
      return null
  }
}

export default MayaErrors
