import React from 'react'
import { Box, Flex, Text } from 'rebass'
import { Sections } from '../MainMenu'
import { styles } from './drawerStyles'
import { ReactComponent as LogoutIcon } from './logout.svg'
import { ReactComponent as MayaIcon } from './maya.svg'
import { ReactComponent as TodoIcon } from './todo.svg'
import { ReactComponent as WellnessIcon } from './wellness.svg'
import { ReactComponent as AppointmentIcon } from './appointment.svg'
import { ReactComponent as DashboardIcon } from './dashboard.svg'
import { ReactComponent as FindIcon } from './find.svg'
import { useTranslation } from 'react-i18next'
import { fnOnEnter } from '../../../utils'

type DrawerProps = {
  collapsed: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>
  setActiveSection: React.Dispatch<React.SetStateAction<Sections>>
  activeSection: string
  onLogout: () => void
}
export const Drawer = ({
  collapsed,
  setCollapsed,
  activeSection,
  setActiveSection,
  onLogout
}: DrawerProps) => {
  const { t } = useTranslation()

  type Sections =
    | 'dashboard'
    | 'appointment'
    | 'find'
    | 'todo-pending'
    | 'wellnessMenu'

  const navigateToSection = (section: Sections) => {
    setActiveSection(section)
    setCollapsed(true)
  }

  const onDashboard = () => navigateToSection('dashboard')

  const onAppointment = () => navigateToSection('appointment')

  const onFind = () => navigateToSection('find')

  const onTodoList = () => navigateToSection('todo-pending')

  const onWellness = () => navigateToSection('wellnessMenu')

  return (
    <Box
      sx={{ minWidth: 'unset', zIndex: 1 }}
      onFocus={() => setCollapsed(false)}
    >
      <Flex as="ul" sx={styles.drawer(collapsed)}>
        <Flex sx={styles.logo}>
          <MayaIcon />
          <Text sx={styles.menuItemLabel(collapsed)}>Maya MD</Text>
        </Flex>

        <Flex sx={styles.menu}>
          {/* <li
            data-selected={activeSection.includes('dashboard')}
            onClick={onDashboard}
            onKeyPress={fnOnEnter(onDashboard)}
            className="focusable-main-menu"
          >
            <DashboardIcon />
            <Text sx={styles.menuItemLabel(collapsed)}>Dashboard</Text>
          </li>

          <li
            data-selected={activeSection.includes('appointment')}
            onClick={onAppointment}
            onKeyPress={fnOnEnter(onAppointment)}
            className="focusable-main-menu"
          >
            <AppointmentIcon />
            <Text sx={styles.menuItemLabel(collapsed)}>Appointments</Text>
          </li>

          <li
            data-selected={activeSection.includes('find')}
            onClick={onFind}
            onKeyPress={fnOnEnter(onFind)}
            className="focusable-main-menu"
          >
            <FindIcon />
            <Text sx={styles.menuItemLabel(collapsed)}>Find a Provider</Text>
          </li> */}

          <li
            data-selected={activeSection.includes('todo')}
            onClick={onTodoList}
            onKeyPress={fnOnEnter(onTodoList)}
            className="focusable-main-menu"
          >
            <TodoIcon />
            <Text sx={styles.menuItemLabel(collapsed)}>
              {t('lg.dashboard.sideMenu.todoList')}
            </Text>
          </li>
          <li
            className="focusable-main-menu"
            onClick={onWellness}
            onKeyPress={fnOnEnter(onWellness)}
            data-selected={activeSection.includes('wellness')}
          >
            <WellnessIcon />
            <Text sx={styles.menuItemLabel(collapsed)}>
              {t('lg.dashboard.sideMenu.wellness')}
            </Text>
          </li>
        </Flex>

        <li
          className="focusable-main-menu"
          onClick={onLogout}
          onKeyPress={fnOnEnter(onLogout)}
        >
          <LogoutIcon />
          <Text sx={styles.menuItemLabel(collapsed)}>
            {t('lg.dashboard.sideMenu.logout')}
          </Text>
        </li>
      </Flex>
    </Box>
  )
}
