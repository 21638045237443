import React from 'react'
import styles from './styles'
import { Box } from 'rebass'

const LoadingSpinner = () => {
  return (
    <Box sx={styles.lds}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Box>
  )
}

export default LoadingSpinner
