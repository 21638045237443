const styles = {
  lds: {
    display: 'inline-block',
    position: 'relative',
    width: '80px',
    height: '80px',

    '& div': {
      position: 'absolute',
      width: '6px',
      height: '6px',
      backgroundColor: 'primary',
      borderRadius: '50%',
      animation: 'lds-animation 1.2s linear infinite'
    },

    '& div:nth-child(1)': {
      animationDelay: '0s',
      top: '37px',
      left: '66px'
    },
    '& div:nth-child(2)': {
      animationDelay: '-0.1s',
      top: '22px',
      left: '62px'
    },
    '& div:nth-child(3)': {
      animationDelay: '-0.2s',
      top: '11px',
      left: '52px'
    },
    '& div:nth-child(4)': {
      animationDelay: '-0.3s',
      top: '7px',
      left: '37px'
    },
    '& div:nth-child(5)': {
      animationDelay: '-0.4s',
      top: '11px',
      left: '22px'
    },
    '& div:nth-child(6)': {
      animationDelay: '-0.5s',
      top: '22px',
      left: '11px'
    },
    '& div:nth-child(7)': {
      animationDelay: '-0.6s',
      top: '37px',
      left: '7px'
    },
    '& div:nth-child(8)': {
      animationDelay: '-0.7s',
      top: '52px',
      left: '11px'
    },
    '& div:nth-child(9)': {
      animationDelay: '-0.8s',
      top: '62px',
      left: '22px'
    },
    '& div:nth-child(10)': {
      animationDelay: '-0.9s',
      top: '66px',
      left: '37px'
    },
    '& div:nth-child(11)': {
      animationDelay: '-1s',
      top: '62px',
      left: '52px'
    },
    '& div:nth-child(12)': {
      animationDelay: '-1.1s',
      top: '52px',
      left: '62px'
    }
  }
}

export default styles
