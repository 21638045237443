export const styles = {
  container: {
    width: '100%',
    height: '100%',
    padding: '16px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    backgroundImage:
      'radial-gradient(100% 100% at 0% 100%, #102E48 18.49%, #104783 51.19%, #47B8F0 83.14%)',
    '&>*:not(:last-child)': {
      mr: '16px'
    }
  },
  videoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    width: '100%',
    height: '100%',
    '& > video': {
      height: '100%',
      width: 'auto'
    }
  }
}
