const styles = {
  container: {
    position: 'absolute',
    right: ['unset', 'unset', 'unset', 'unset', 77, 77],
    top: '50%',
    left: ['50%', '50%', '50%', '50%', 'unset', 'unset'],
    transform: [
      'translate(-50%, -50%)',
      'translate(-50%, -50%)',
      'translate(-50%, -50%)',
      'translate(-50%, -50%)',
      'translateY(-50%)',
      'translateY(-50%)'
    ],
    alignSelf: 'center',
    width: ['95%', '95%', 'auto', 'auto', 634, 634],
    zIndex: 1,
    minHeight: 136,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'backgroundPrimary',
    borderRadius: 'card',
    backdropFilter: 'blur(50px)',
    color: 'text'
  },
  warning: {
    minHeight: 136,
    width: 619,
    borderRadius: '0 8px 8px 0',
    justifyContent: 'center',
    flexDirection: 'column',
    px: 7,
    py: 7
  },
  divider: {
    background: '#C4C4C4',
    opacity: 0.2,
    width: '100%',
    height: '1px',
    my: 8
  },
  heading: {
    display: 'inline-flex',
    fontWeight: 'bold',
    width: 'fit-content',
    fontSize: 5,
    textAlign: 'center'
  },
  icon: {
    color: 'error',
    alignItems: 'center',
    width: '24px',
    mr: 2
  },
  message: {
    my: 8,
    fontSize: 2
    // textAlign: 'left'
  },
  closeButton: {
    maxWidth: 'fit-content',
    mb: 5
  }
}

export default styles
