const styles = {
  container: {
    alignSelf: 'center',
    width: ['100%', '100%', '100%', '100%', 560, 560],
    zIndex: 1,
    alignItems: [
      'center',
      'center',
      'center',
      'center',
      'flex-start',
      'flex-start'
    ],
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'backgroundPrimary',
    borderRadius: ['unset', 'unset', 'unset', 'unset', 'card', 'card'],
    backdropFilter: 'blur(50px)',
    color: 'text',
    p: [6, 6, 10, 10, 10, 10],
    textAlign: 'center'
  },
  actions: {
    mt: [10, 10, 10, 10, 11, 11],
    '& > *:not(:last-child)': {
      mr: [6, 6, 6, 6, 8, 8]
    }
  }
}

export default styles
