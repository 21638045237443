import { useContext } from 'react'
import WidgetContext from '../contexts/WidgetContext'

const useWidgetContext = () => {
  const widgetContext = useContext(WidgetContext)

  return widgetContext
    ? widgetContext
    : { widgetMode: false, fullscreen: false }
}

export default useWidgetContext
