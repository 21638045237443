/**
 * Object.entriesFrom() polyfill
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!Object.fromEntries) {
  console.log('no fromEntries, adding')
  Object.fromEntries = function(entries) {
    if (!entries || !entries[Symbol.iterator]) {
      throw new Error(
        'Object.fromEntries() requires a single iterable argument'
      )
    }
    let obj = {}
    for (let [key, value] of entries) {
      obj[key] = value
    }
    return obj
  }
}
