import { Reducer } from 'react'

const debug = process.env.NODE_ENV === 'development'

const runReducers = (
  reducers: Reducer<any, any>[],
  state: any,
  action: any
) => {
  if (debug) console.groupCollapsed('Action -', action.type)

  const newState = reducers.reduce(
    (state, reducer) => reducer(state, action),
    state
  )

  if (debug) {
    console.groupCollapsed('state')
    console.log(newState)
    console.groupEnd()

    console.groupEnd()
  }
  return newState
}

export default runReducers
