import { Box, SxStyleProp, Text } from 'rebass'
import React from 'react'
import styles from './styles'
import { LanguageOption } from './LanguageOption'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'
import { VscChevronDown } from 'react-icons/vsc'

interface LanguageSelectorProps {
  supportedLanguages: LanguageOption[]
  languageSelected: string
  sx?: SxStyleProp
}
const LanguageSelector = ({
  supportedLanguages,
  languageSelected,
  sx
}: LanguageSelectorProps) => {
  const sendMessage = useSendSocketMessage()

  const setNewLanguage = (langKey: string) => {
    sendMessage({ type: 'i18n', key: langKey })
  }

  if (supportedLanguages.length === 0) {
    return null
  }

  return (
    <Box sx={{ ...(styles.selectContainer as any), ...sx }}>
      <Box
        as="select"
        variant="inputSelect"
        sx={styles.select}
        value={languageSelected}
        onChange={(e: any) => setNewLanguage(e.target.value)}
      >
        {supportedLanguages.map(lang => (
          <Text as="option" value={lang.code} key={lang.code}>
            {lang.name}
          </Text>
        ))}
      </Box>
      <Box sx={styles.selectArrow}>
        <VscChevronDown />
      </Box>
    </Box>
  )
}

export default LanguageSelector
