import { useEffect } from 'react'
import { ws } from '../../socket'

const useSocketEventHandler = (
  handler: (event: string, payload?: any) => void
) => {
  useEffect(() => {
    attachEvents(ws, handler)
  }, [handler])

  const attachEvents = (ws: any, handler: Function) => {
    const connected = () => {
      console.info('WS CONNECTED:', ws.id)
      handler('socketConnected')
    }
    if (1 === ws.readyState) {
      connected()
    } else {
      ws.addEventListener('open', connected)
    }
    ws.addEventListener('message', (event: any) => {
      try {
        const payload = JSON.parse(event.data)
        handler('message', payload)
      } catch (error) {
        console.error('Socket data parse error: ', error)
      }
    })

    ws.addEventListener('error', (error: Error) => {
      handler('error', error)
    })
    ws.addEventListener('close', (reason: string) => {
      handler('disconnect', reason)
    })
  }
}

export default useSocketEventHandler
