export const styles = {
  container: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    alignSelf: 'center',
    width: ['95%', '95%', 560, 560, 560, 560],
    zIndex: 15,
    flexDirection: 'column',
    p: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'backgroundPrimary',
    borderRadius: 'card',
    backdropFilter: 'blur(100px)',
    color: 'text'
  },
  heading: {
    fontSize: 5,
    mb: 5,
    textAlign: 'center'
  },
  text: {
    fontSize: 1,
    color: 'text',
    textAlign: 'center'
  },
  time: {
    fontWeight: '400',
    fontSize: 3,
    pr: 3
  },
  progress: {
    mt: 4,
    alignItems: 'center',
    '& .react-sweet-progress-symbol': { display: 'none' },
    '& .react-sweet-progress': {
      width: '10rem',
      ml: 1
    },
    '& .react-sweet-progress-line-inner': {
      minHeight: '5px'
    },
    '& .react-sweet-progress-line-inner-status-active:before': {
      animation: 'none'
    }
  },
  instructions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: '#333',
    my: 3,
    fontSize: 2
  },
  buttonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    mt: 12,
    width: '100%',
    justifyContent: 'center'
  },
  leaveChatButton: {
    width: '8rem',
    mr: 2
  },
  backToChatButton: {
    width: '8rem'
  }
}

export default styles
