export const styles = {
  container: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    alignSelf: 'center',
    width: ['95%', '95%', 560, 560, 560, 560],
    zIndex: 1,
    flexDirection: 'column',
    p: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'backgroundPrimary',
    borderRadius: 'card',
    backdropFilter: 'blur(100px)',
    color: 'text'
  },
  heading: {
    fontSize: 5,
    fontWeight: 'bold',
    mb: 2,
    textAlign: 'center'
  },
  text: {
    fontSize: 1,
    color: 'text',
    textAlign: 'center',
    mb: 5
  },
  buttonsContainer: {
    mt: 12,
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    '& *:not(:last-child)': {
      mr: 2
    }
  },
  backToChatButton: {}
}

export default styles
