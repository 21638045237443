const styles = {
  select: {},
  selectContainer: {
    zIndex: 99,
    position: 'absolute',
    top: '2rem',
    right: '51px',
    bg: 'backgroundPrimary',
    color: 'text',
    backdropFilter: 'blur(50px)',
    display: 'flex',
    borderRadius: 'buttonBig',
    alignItems: 'center'
  },
  selectArrow: {
    pointerEvents: 'none',
    lineHeight: 1,
    ml: -8,
    transform: 'translateY(1px)',
    '& svg': {
      color: 'textPrimary'
    }
  }
}

export default styles
