import React, { createElement } from 'react'
// @ts-ignore
import marksy from 'marksy'
import { Text, Flex, Image, Heading, Link, Box, Button } from 'rebass'
import { Label } from '@rebass/forms'
import styles from './styles'
import InfoIcon from './informationIcon.png'

interface ActionButtonProps {
  text: string
  action: string
  link?: string
  keepInfoOpen: boolean
  important: boolean
}

export const compile = (sendMessage: (request: any) => void, dispatch: any) =>
  marksy({
    elements: {
      a: ({ href, children, ...otherProps }: any) => {
        if (href.includes('maya-page://')) {
          const prefixRemoved = href.split('maya-page://')[1]
          const [pageId, params] = prefixRemoved.split('/?')

          const formattedParams = params
            ? params
                .replace(/&amp;/g, '&')
                .split('&')
                .map((keyval: string) => {
                  const [key, value] = keyval.split('=')
                  return { [key]: decodeURIComponent(value) }
                })
                .reduce(
                  (
                    obj: Record<string, string>,
                    curr: Record<string, string>
                  ) => ({ ...obj, ...curr }),
                  {}
                )
            : {}
          return (
            <Text
              as="a"
              className="focusable"
              sx={
                children.some((el: any) => el?.props?.src === '/info.png')
                  ? {}
                  : styles.mayaPageLink
              }
              onClick={() => {
                dispatch({
                  type: 'mayaMessage',
                  payload: { type: 'storeInfoPage' }
                })
                sendMessage({
                  type: 'infoPage',
                  pageId: pageId,
                  params: formattedParams
                })
              }}
            >
              {children}
            </Text>
          )
        } else {
          return (
            <Link
              href={unescape(href)}
              {...otherProps}
              target="_blank"
              rel="noopener noreferrer"
              sx={styles.anchor}
            >
              {children}
            </Link>
          )
        }
      },
      h1: ({ children }: any) => (
        <Heading as="h1" sx={styles.heading}>
          {children}
        </Heading>
      ),
      h2: ({ children }: any) => (
        <Heading as="h2" sx={styles.headingTwo}>
          {children}
        </Heading>
      ),
      h3: ({ children }: any) => (
        <Heading as="h3" sx={styles.headingThree}>
          {children}
        </Heading>
      ),
      h4: ({ children }: any) => (
        <Heading as="h4" sx={styles.headingFour}>
          {children}
        </Heading>
      ),
      h5: ({ children }: any) => (
        <Heading as="h5" sx={styles.headingFive}>
          {children}
        </Heading>
      ),
      h6: ({ children }: any) => (
        <Heading as="h6" sx={styles.headingSix}>
          {children}
        </Heading>
      ),
      hr: ({ children }: any) => <hr style={styles.hr}>{children}</hr>,
      p: ({ children }: any) => <Text sx={styles.paragraph}>{children}</Text>,
      img({ src, alt, title }: any) {
        if (alt.includes('progressbar')) {
          const color = alt.split('-')[1]
          const percentage = src.replace(/\D+/g, '')
          return (
            <Flex sx={styles.loading.barContainer}>
              <Flex sx={styles.loading.barInnerContainer}>
                <Flex
                  sx={{
                    ...styles.loading.bar,
                    backgroundColor: color ? color : 'primary',
                    width: `${percentage}%`
                  }}
                />
              </Flex>
            </Flex>
          )
        } else if (src === '/info.png') {
          return (
            <Image
              sx={{
                cursor: 'pointer',
                width: '18px',
                height: '18px',
                verticalAlign: 'text-top'
              }}
              src={InfoIcon}
            />
          )
        } else {
          return <Image src={src} alt={alt} />
        }
      },
      del: ({ children }: any) => {
        return (
          <Box
            sx={{
              '& .collapsible:checked ~ .content': {
                display: 'block'
              },
              '& .content': {
                display: 'none'
              }
            }}
          >
            <Label>
              <input
                type="checkbox"
                className="collapsible"
                style={{ display: 'none' }}
              />
              <Box variant="whiteInverted" sx={styles.showMoreButton}>
                Show More
              </Box>
              <Box className="content" sx={{ display: 'none' }}>
                {children}
              </Box>
            </Label>
          </Box>
        )
      }
    },

    createElement
  })

export const buttons = (
  buttons: ActionButtonProps[],
  onClick?: (action: string, keepInfoOpen: boolean) => void
) => {
  if (buttons) {
    const importantButtons = buttons.filter(
      (btn: ActionButtonProps) => btn.important
    )
    const restOfButtons = buttons.filter(
      (btn: ActionButtonProps) => !btn.important
    )

    return importantButtons
      .concat(restOfButtons)
      .map(
        ({
          text,
          action,
          keepInfoOpen,
          link,
          important
        }: ActionButtonProps) => {
          const handleClick = () => {
            if (link) {
              window.open(link, action)
              return onClick && onClick(action, true)
            }

            onClick && onClick(action, true)
          }

          return (
            <Button
              type="submit"
              key={action}
              onClick={handleClick}
              variant={important ? 'informationImportant' : 'information'}
            >
              {text}
            </Button>
          )
        }
      )
  }

  return null
}
