import React from 'react'
import { Text, Image } from 'rebass'
import mayaLogo from '../../app/assets/img/logo.png'

type ResponsibilityDisclaimerOverlayProps = {
  disclaimerImage?: string
  disclaimerText?: string
}
export const ResponsibilityDisclaimerOverlay = ({
  disclaimerImage,
  disclaimerText
}: ResponsibilityDisclaimerOverlayProps) => {
  return (
    <>
      <Text
        sx={{
          position: 'absolute',
          top: '32px',
          right: '16px',
          zIndex: 4,
          writingMode: 'vertical-lr',
          transform: 'rotate(180deg)',
          color: 'optionText'
        }}
      >
        {disclaimerText}
      </Text>
      <Image
        sx={{
          width: '24px',
          position: 'absolute',
          bottom: ['unset', 'unset', 'unset', 'unset', '16px'],
          left: '16px',
          top: ['30%', '30%', '30%', '50%', 'unset'],
          transform: [
            'translateY(-50%)',
            'translateY(-50%)',
            'translateY(-50%)',
            'translateY(-50%)',
            'unset'
          ],
          zIndex: 4,
          mixBlendMode: 'difference',
          opacity: '0.5'
        }}
        src={disclaimerImage}
      />
      <Image
        src={mayaLogo}
        sx={{
          position: 'absolute',
          bottom: '32px',
          right: '52px',
          display: ['none', 'none', 'none', 'none', 'block'],
          zIndex: 4,
          width: '190px'
        }}
      />
    </>
  )
}
