export default {
  container: {
    flex: 1,
    label: 'transcript-container',
    zIndex: 'transcript',
    backgroundColor: 'backgroundPrimary',
    overflow: 'auto',
    borderRadius: ['unset', 'unset', 'unset', 'unset', 'card', 'card'],
    backdropFilter: 'blur(50px)',
    width: ['100%', '100%', '100%', '100%', 560, 560]
  },
  widgetContainer: {
    minHeight: ['unset', 'unset', 'unset', 'unset', '200px', '200px']
  },
  headerDivider: {
    minWidth: '95%'
  },
  divider: {
    background: '#C4C4C4',
    opacity: 0.2,
    width: '100%',
    height: '1px',
    mt: [6, 6, 10, 10, 10, 10]
  },
  transcript: {
    label: 'Transcript',
    flexDirection: 'column',
    width: '100%',
    p: [6, 6, 10, 10, 10, 10]
  },
  closeIcon: {
    width: 'auto',
    height: '100%',
    p: 2,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: 'backgroundSecondary',
    borderRadius: 'buttonBig',
    cursor: 'pointer',

    '& svg': {
      minWidth: 16,
      height: 16,
      margin: 0
    },
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)'
    },
    mr: 5
  },
  transcriptImage: {
    width: 23,
    height: 23
  },

  header: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    fontSize: 4,
    color: 'text',
    zIndex: 3,
    mb: 12
  },

  headerText: {
    fontWeight: 400,
    letterSpacing: '-0.01em',
    fontSize: '24px',
    lineHeight: '32px'
  },

  downloadIcon: {
    cursor: 'pointer',
    width: '24px',
    height: '24px',
    mt: '10px',
    ml: '20px',
    '@media (hover: hover)': {
      '&:hover .download-tooltip': { display: 'block' }
    }
  },
  tooltip: {
    backgroundColor: 'text',
    color: 'text',
    borderRadius: '4px',
    p: '4px 8px',
    fontSize: 2,
    display: 'none',
    zIndex: 5,
    position: 'absolute'
  },
  pinButton: {
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover .transcript-tooltip': { display: 'block' }
    }
  },
  pinIcon: {
    width: '20px',
    ml: '6px',
    mr: '15px',
    '& svg': {
      minWidth: '24px'
    }
  },
  scrollArea: {
    flex: 1,
    width: '100%',
    overflow: 'auto',
    justifyContent: 'flex-end'
  },
  scrollContent: {
    overflowY: 'auto',
    minHeight: '100%',
    display: 'flex',
    maxWidth: '100%',
    width: '100%',
    flexDirection: 'column',
    mt: 'auto',
    '& > *:not(:last-child)': {
      mb: 6
    }
  },
  question: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& svg': {
      minWidth: '16px',
      width: '16px',
      height: '16px',
      margin: 0,
      fill: 'text',
      cursor: 'pointer'
    }
  },
  message: {
    fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
    color: 'text',
    letterSpacing: '-0.01em',
    display: 'flex',
    width: 'auto',
    '&:focus': {
      border: '1px solid grey'
    }
  },
  avatarMessage: {
    fontWeight: '500',
    px: '12px',
    py: '10.5px',
    ml: '8px',
    lineHeight: ['14px', '14px', '24px', '24px', '24px', '24px'],
    // textAlign: 'start',
    backgroundColor: 'backgroundSecondary',
    borderRadius: '4px',
    alignSelf: 'flex-start',
    color: 'textTranscript'
  },
  userMessage: {
    px: '12px',
    py: '8px',
    fontWeight: '400',
    lineHeight: ['14px', '14px', '19px', '19px', '19px', '19px'],
    textAlign: 'end',
    border: '1px solid',
    borderColor: 'text',
    borderRadius: '4px',
    alignSelf: 'flex-end'
  },
  link: {
    color: '#333'
  }
}
