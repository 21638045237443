import React from 'react'
import { Todo } from '../../TvApp/types/Todo'
const TvContext = React.createContext<{
  tvAppMode: boolean
  wellnessCheckInTodoId: string
  setWellnessCheckInTodoId: any
  symptomIds: any
  todoList: Todo[] | null
  setSymptomIds: any
  setTodoList: (todoList: Todo[] | null) => void
  session: string
}>({
  tvAppMode: false,
  wellnessCheckInTodoId: '',
  setWellnessCheckInTodoId: () => {},
  symptomIds: [],
  todoList: [],
  setSymptomIds: () => {},
  setTodoList: () => {},
  session: ''
})
export default TvContext
