const mayaStatementReducer = (config?: any) => (state: any, action: any) => {
  switch (action.type) {
    case 'mayaMessage':
      switch (action?.payload?.type) {
        case 'statement':
          return {
            ...state,
            mayaQuestion: null,
            mayaInformation: null,
            statement: action.payload
          }
        case 'restartSession':
          return {
            ...state,
            statement: null
          }
        default:
          return state
      }
    default:
      return state
  }
}

export default mayaStatementReducer
