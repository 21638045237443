import React, { useContext, useEffect, useRef } from 'react'
import { Box, Flex, Text } from 'rebass'
import { UneeqContext, useTranscript, useUneeqState } from 'uneeq-react-core'
import styles from './styles'
import TranscriptHeader from './TranscriptHeader'
import TranscriptMessage from './TranscriptMessage'
import { AnimatePresence, motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import useWidgetContext from '../../app/hooks/useWidgetContext'
import { useTvContext } from '../../app/hooks/useTvContext'

const MotionBox: any = motion.custom(Box)

interface TranscriptItemCommon {
  type: String
  time: Date
}

export interface TranscriptItem {
  [key: string]: any | TranscriptItemCommon
}

declare var SpatialNavigation: any

const Transcript: React.FC = () => {
  const { dispatch } = useContext(UneeqContext)
  const { transcriptOpen } = useUneeqState()
  const { widgetMode, fullscreen } = useWidgetContext()
  const { downloadTranscript, transcript } = useTranscript()
  const { tvAppMode } = useTvContext()
  const { t } = useTranslation()

  const firstItemRef = useRef<HTMLDivElement>(null)

  // autoscroll to bottom
  const scrollArea = useRef<HTMLElement>()
  useEffect(() => {
    console.log('transcript', transcript)
    if (scrollArea && scrollArea.current) {
      scrollArea.current.scrollTop =
        scrollArea?.current.scrollHeight - scrollArea?.current.clientHeight
    }
  }, [transcriptOpen, transcript])

  const close = () => dispatch({ type: 'closeTranscript' })

  const height = window.screen.availHeight

  useEffect(() => {
    if (tvAppMode && transcriptOpen) {
      SpatialNavigation.makeFocusable()

      // set focus on first item
      if (firstItemRef.current) {
        setTimeout(() => {
          firstItemRef.current?.focus()
        }, 300)
      }
    }
  }, [transcriptOpen, tvAppMode, transcript.length])

  return (
    <AnimatePresence>
      {transcriptOpen && (
        <MotionBox
          variant="transcriptContainer"
          initial={{ transform: `translate(0px, 50px)` }}
          animate={{ transform: `translate(0px, 0px)` }}
          exit={{
            transform: `translate(0px, ${height * 1.5}px)`
          }}
          sx={{
            ...styles.container,
            ...(widgetMode && !fullscreen ? styles.widgetContainer : {})
          }}
        >
          <Flex variant="transcript" sx={styles.transcript}>
            <TranscriptHeader
              close={close}
              downloadTranscript={downloadTranscript}
            />
            <Flex
              ref={scrollArea}
              className="transcript-content"
              sx={styles.scrollArea}
            >
              <Box sx={styles.scrollContent}>
                {transcript.length ? (
                  transcript.map((item: TranscriptItem, i: number) => (
                    <TranscriptMessage
                      key={item.time.valueOf()}
                      item={item}
                      showUndo={i !== transcript.length - 1}
                    />
                  ))
                ) : (
                  <Text>{t('Transcript.noTranscriptAvailable')}</Text>
                )}
              </Box>
            </Flex>
          </Flex>
        </MotionBox>
      )}
    </AnimatePresence>
  )
}

export default Transcript
