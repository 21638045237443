import React from 'react'
import { Box, Flex, Text } from 'rebass'
import { ReactComponent as ExclamationIcon } from '../../customPlugins/MayaErrors/exclamation.svg'
import styles from './styles'

const SmallScreen = () => {
  return (
    <Box sx={styles.smallScreenContainer}>
      <Flex>
        <Flex sx={styles.icon}>
          <ExclamationIcon />
        </Flex>
        <Text sx={styles.heading}>Rotate your device</Text>
      </Flex>
      <Text mt={5}>
        Please, rotate your device screen in order to use the app
      </Text>
    </Box>
  )
}

export default SmallScreen
