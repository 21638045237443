import React from 'react'
import { Text, Box, Image } from 'rebass'
import FatalError from '../customPlugins/MayaErrors/FatalError'
import { useTranslation } from 'react-i18next'
interface SessionTimedOutProps {
  restart: () => void
  logo?: string
  widgetMode?: boolean
}
const SessionTimedOut: React.FC<SessionTimedOutProps> = ({
  restart,
  logo,
  widgetMode
}) => {
  const { t } = useTranslation()
  return (
    <>
      {logo && !widgetMode && (
        <Box variant="homeLogo">
          <Image src={logo} alt="Logo"></Image>
        </Box>
      )}
      <FatalError
        clearError={restart}
        errorTitle={t('Timeout.expiredHeading')}
        buttonText={t('Timeout.expiredButton')}
      >
        <Text>{t('Timeout.expiredSubtitle')}</Text>
      </FatalError>
    </>
  )
}

export default SessionTimedOut
