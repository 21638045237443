import logo1 from './assets/img/logo.png'
import logo2 from './assets/img/logo2.png'

const logos = {
  logo1: logo1,
  logo2: logo2
}

export const downloadPdf = async (
  type: 'transcript' | 'savedItems',
  items: any,
  sessionId: string
) => {
  import('uneeq-download-pdf').then(
    ({ downloadSavedItemsPdf, downloadTranscriptPdf }) => {
      if (type === 'transcript') {
        downloadTranscriptPdf({
          filename: 'maya-transcript',
          content: items,
          sessionId,
          ...logos
        })
      } else {
        downloadSavedItemsPdf({
          filename: 'maya-information',
          content: items,
          sessionId,
          ...logos
        })
      }
    }
  )
}

export default downloadPdf
