const styles = {
  container: {
    label: 'dhContainer',
    position: 'absolute',
    height: ['100%', '100%', '100%', '100%', '100%', '100%'],
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%'
  },
  heading: {
    display: 'inline-flex',
    width: 'fit-content',
    fontSize: 5
  },
  icon: {
    color: 'error',
    alignItems: 'center',
    width: '24px',
    mr: 2
  },
  smallScreenContainer: {
    label: 'portrait',
    display: 'none',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    alignSelf: 'center',
    width: ['95%', '95%', 560, 560, 560, 560],
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgba(255,255,255,0.1)',
    padding: 10,
    borderRadius: 'card',
    backdropFilter: 'blur(100px)',
    color: 'white'
  },
  interactionContainer: {
    label: 'interactionContainer',
    height: ['unset', 'unset', 'unset', 'unset', '100vh', '100vh'],
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: ['static', 'static', 'static', 'static', 'fixed', 'fixed'],
    bottom: ['auto', 'auto', 'auto', 'auto', 0, 0],
    left: ['auto', 'auto', 'auto', 'auto', 0, 0],
    top: ['auto', 'auto', 'auto', 'auto', 0, 0],
    p: [0, 0, 0, 0, 5, 5],
    '& > *:not(:last-child)': {
      mb: [0, 0, 0, 0, 1, 1]
    },
    transition: 'all .4s ease-in-out',
    zIndex: 5
  }
}

export default styles
