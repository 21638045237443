export const styles = {
  card: (active: boolean) => ({
    label: 'TaskList-card',
    flexDirection: 'column',
    bg: active ? 'backgroundPrimary' : '#32373A',
    opacity: active ? 1 : 0.4,
    color: 'white',
    p: '32px',
    borderRadius: '8px',
    width: '100%',
    transition: 'all .4s'
  }),
  header: {
    label: 'TaskList-header',
    // flexDirection: 'column',
    // display:' '
    mb: '32px'
  },
  title: {
    label: 'TaskList-title',
    fontSize: '23px',
    lineHeight: '30px',
    fontWeight: 700,
    opacity: 0.7
  },
  subtitle: {
    label: 'TaskList-subtitle',
    fontSize: '48px',
    lineHeight: '56px',
    fontWeight: 700
  },
  list: {
    label: 'TaskList-list',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  label: {
    label: 'TaskList-label',
    position: 'relative',
    display: 'block!important',
    my: '0.5rem',
    '&:focus': {
      bg: 'orangered'
    }
  },
  inputContainer: {
    label: 'TaskList-inputContainer',
    display: 'flex',
    alignItems: 'center',
    '& > input:focus': {
      '& ~ div': {
        opacity: 1
      },
      '& + div': {
        border: '8px solid white'
      }
    },
    '& > input:checked + div': {
      background: 'rgba(255, 255, 255, 0.4)',
      '& > svg': {
        display: 'block'
      }
    },
    cursor: 'pointer'
  },
  wellnessCheck: {
    label: 'TaskList-wellnessCheck',
    minWidth: '48px',
    width: '48px',
    height: '48px',
    boxSizing: 'content-box',
    alignItems: 'center',
    justifyContent: 'center'
  },
  checkbox: {
    label: 'TaskList-checkbox',
    minWidth: '48px',
    width: '48px',
    height: '48px',
    boxSizing: 'content-box',
    borderRadius: '4px',
    border: '2px solid rgba(255, 255, 255, 0.1)',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      display: 'none'
    }
  },
  checkboxTextContainer: {
    label: 'TaskList-checkboxTextContainer',
    ml: '1rem',
    flexDirection: 'column',
    opacity: 0.7
  },
  checkboxTitle: {
    label: 'TaskList-checkboxTitle',
    fontSize: '29px',
    lineHeight: '36px',
    fontWeight: 700
  },
  checkboxSubtitle: {
    label: 'TaskList-checkboxSubtitle',
    fontSize: '25px',
    lineHeight: '32px'
  },
  wellnessCheckinButton: {
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 700,
    color: 'backgroundPrimary',
    padding: '12px 16px',
    bg: 'white',
    textAlign: 'start',
    ml: '16px',
    cursor: 'pointer',
    transition: 'background-color .4s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:focus': {
      bg: 'white',
      color: 'backgroundPrimary'
    },
    '& svg': {
      width: '18px',
      height: '18px',
      ml: '10px'
    }
  }
}
