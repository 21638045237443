const mayaInformationReducer = (config?: any) => (state: any, action: any) => {
  switch (action.type) {
    case 'mayaMessage':
      switch (action?.payload?.type) {
        case 'infoPage':
          return {
            ...state,
            mayaInformation: action.payload,
            mayaQuestion: null,
            questionLoading: false
          }
        case 'clearInfoPage':
          return { ...state, mayaInformation: null }
        case 'storeInfoPage':
          return { ...state, previousMayaInformation: state.mayaInformation }
        case 'restoreInfoPage':
          return {
            ...state,
            mayaInformation: state.previousMayaInformation,
            previousMayaInformation: null
          }
        default:
          return state
      }
    default:
      return state
  }
}

export default mayaInformationReducer
