import { useEffect, useState } from 'react'

type UseAutoResizeProps = {
  condition: () => boolean
}
export const useAutoResize = ({
  condition = () => true
}: UseAutoResizeProps) => {
  // @ts-ignore
  const viewport = window?.visualViewport
  const [height, setHeight] = useState(viewport?.height)

  useEffect(() => {
    viewport?.addEventListener('resize', () => {
      if (condition()) {
        setHeight(viewport.height)
      }
    })
  }, [condition, viewport])

  return height
}
