const mayaDocumentTitleReducer = (defaultTitle: string) => (
  state: any,
  action: any
) => {
  switch (action.type) {
    case 'setConfig':
      return {
        ...state,
        documentTitle: action.payload.title
          ? action.payload.title
          : defaultTitle
      }
    default:
      return state
  }
}

export default mayaDocumentTitleReducer
