export const styles = {
  drawer: (collapsed: boolean) => ({
    listStyle: 'none',
    minWidth: collapsed ? '0px' : '440px',
    bg: collapsed ? 'rgba(50, 55, 58, 0.4)' : 'backgroundPrimary',
    height: '100%',
    width: 'max-content',
    padding: '12px',
    borderRadius: '8px',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '29px',
    lineHeight: '36px',
    transition: 'all .6s',
    '& svg': {
      width: '40px',
      height: '40px'
    },
    '& li': {
      display: 'flex',
      position: 'relative',
      cursor: 'pointer',
      borderRadius: '8px',
      width: '100%',
      py: '22px',
      px: '10px',
      mb: '16px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      color: 'white',
      border: '2px solid transparent',
      transition: 'color .2s, background .2s',
      '&:focus': {
        borderColor: 'white',
        bg: 'backgroundSecondary'
      },
      '&[data-selected="true"]': {
        bg: collapsed ? 'rgba(255, 255, 255, 0.1)' : 'white',
        color: collapsed ? 'white' : 'backgroundPrimary',
        borderColor: collapsed ? 'rgba(255, 255, 255, 0.1)' : 'transparent'
      }
    }
  }),
  menuItemLabel: (collapsed: boolean) => ({
    opacity: collapsed ? '0' : '1',
    position: 'absolute',
    transform: collapsed ? 'translate(-500%, -50%)' : 'translate(0px, -50%)',
    transition: 'opacity .2s .2s',
    left: '60px',
    top: '50%',
    whiteSpace: 'nowrap'
  }),
  logo: {
    width: '100%',
    position: 'relative',
    py: '22px',
    px: '10px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: 'white'
  },
  menu: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%'
  }
}
