import React from 'react'
import { Flex, Text } from 'rebass'
import { useSendSocketMessage } from '../../../app/hooks/useSendSocketMessage'
import {
  buttons,
  compile
} from '../../../customPlugins/MayaInformation/compileMarkdown'
import { StorageKeys } from '../../types/StorageKeys'
import { styles } from './disclaimerStyles'

type DisclaimerProps = {
  disclaimerInfo: any // todo: type
  handleAccept: () => void
  handleDecline: () => void
}

export const Disclaimer = ({
  disclaimerInfo,
  handleAccept,
  handleDecline
}: DisclaimerProps) => {
  const send = useSendSocketMessage()

  if (localStorage.getItem(StorageKeys.DISCLAIMER_SHOWN)) {
    // Disclaimer is already accepted
    // We send the action to accept the disclaimer so the algorithm don't show it before it starts
    send({ type: 'button', action: 'onlyAcceptDisclaimer' })
    handleAccept()
  }

  if (!disclaimerInfo) {
    return <Text>Loading...</Text>
  }

  const compiledMarkdown = compile(send, () => {})(disclaimerInfo.markdown)

  const actionButtons = buttons(disclaimerInfo?.buttons || [], action => {
    send({ type: 'button', action })
    if (action === 'onlyAcceptDisclaimer') {
      handleAccept()
    } else if (action === 'rejectDisclaimer') {
      handleDecline()
    }
  })

  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.informationContainer}>
        <Flex sx={styles.content}>{compiledMarkdown.tree}</Flex>
        <Flex sx={styles.column}>
          <Flex sx={styles.divider} />
          <Flex id="info-actions" sx={styles.buttonsContainer}>
            {actionButtons}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
