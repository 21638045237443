import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../styles'
import { Box, Image, SxStyleProp, Text } from 'rebass'
import CellPhoneButton from './CallPhoneButton'
import { Hospital } from '../types/Hospital'
import { BsStarFill, BsStar, BsStarHalf } from 'react-icons/bs'
import { Position } from '../types/Position'
import DirectionsButton from './DirectionsButton'
import { getPosition } from '../utils/getPosition'

interface HospitalCardProps {
  hospital: Hospital
  sx: SxStyleProp
  userLocation: Position
}

const HospitalRating = React.memo(
  ({ rating }: { rating: number | undefined }) => {
    if (!rating) {
      return null
    }
    const entireStars = Math.trunc(rating)
    const halfStar = rating % 1 !== 0
    const emptyStars = 5 - entireStars - (halfStar ? 1 : 0)

    const stars = new Array(entireStars)
      .fill(1)
      .map(() => <BsStarFill />)
      .concat(halfStar ? [<BsStarHalf />] : [])
      .concat(new Array(emptyStars).fill(1).map(() => <BsStar />))

    return (
      <Box sx={styles.hospitalRating}>
        <Box as="span" sx={styles.hospitalRatingInfo}>{`${entireStars}.${
          halfStar ? 5 : 0
        } `}</Box>
        <Box as="span" sx={styles.hospitalRatingStars}>
          {stars}
        </Box>
      </Box>
    )
  }
)

const HospitalCard = ({ hospital, sx, userLocation }: HospitalCardProps) => {
  const shouldShowImage = Boolean(hospital.image)
  const { t } = useTranslation()

  return (
    <Box
      sx={{
        ...styles.overlay,
        ...sx,
        ...(!shouldShowImage ? { justifyContent: 'flex-end' } : {})
      }}
      key={hospital.name}
    >
      {shouldShowImage && (
        <Box sx={styles.imageContainer}>
          <Image src={hospital.image} />
        </Box>
      )}
      <Box sx={styles.hospitalContainer}>
        <Box sx={styles.hospitalDescription}>
          <Text sx={styles.hospitalName}>{hospital.name}</Text>
          <Text sx={styles.hospitalInfo}>
            <HospitalRating rating={hospital.rating} />
            {/* 4.0 &#9734; &#9734; &#9734; &#9734;{' '} */}
            {hospital.reviews ? `(${hospital.reviews})` : ''}{' '}
            {hospital.reviews && hospital.distance ? ' • ' : ''}
            {hospital.distance
              ? `${hospital.distance} ${hospital.distanceUnit}.`
              : ''}
          </Text>
          <Text sx={styles.hospitalInfo}>
            {hospital.address}{' '}
            {Boolean(hospital.phone) && (
              <Text as="span" sx={styles.phone}>
                • {hospital.phone}
              </Text>
            )}
          </Text>
          <Text sx={styles.hospitalInfo}>
            <Box
              as="span"
              sx={{
                ...(hospital.isOpen
                  ? { color: '#219653' }
                  : { color: '#ff7511' })
              }}
            >
              {t(hospital.isOpen ? 'Map.open' : 'Map.closed')}
            </Box>{' '}
            {hospital.closeAt
              ? `• ${t('Map.closeAt', { time: hospital.closeAt })}`
              : ''}
          </Text>
        </Box>
        {Boolean(hospital.phone) && (
          <CellPhoneButton
            onClick={() => (window.location.href = `tel:${hospital.phone}`)}
            sx={{}}
          />
        )}
        <DirectionsButton
          destination={getPosition(hospital) as Position}
          origin={userLocation}
        />
      </Box>
    </Box>
  )
}

export default HospitalCard
