import React from 'react'
import { Flex } from 'rebass'
import { useTranslation } from 'react-i18next'
import styles from './styles'

interface TranscriptHeaderProps {
  close: () => void
  downloadTranscript: () => void
}
const TranscriptHeader: React.FC<TranscriptHeaderProps> = ({ close }) => {
  const { t } = useTranslation()
  return (
    <Flex sx={styles.header} className="transcript-header">
      <Flex>{t('Transcript.transcript')}</Flex>
      {/* <Flex sx={styles.closeIcon} onClick={close}>
        <CloseIcon />
      </Flex> */}
    </Flex>
  )
}

export default TranscriptHeader
