import React, { useContext, useEffect, useState, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { stripSsml, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { useSendSocketMessage } from '../../../app/hooks/useSendSocketMessage'
import { Button, Flex, Text } from 'rebass'
import styles from '../styles'
import { Select } from '@rebass/forms'
import { markdownQuestion } from '../Question'

const CountryQuestion = () => {
  const { dispatch, countries } = useContext(UneeqContext)
  const { t } = useTranslation()
  const sendMessage = useSendSocketMessage()
  const { mayaQuestion, hideQuestionTitle } = useUneeqState()
  const [showSelect, setShowSelect] = useState(false)
  const [option, setOption] = useState(countries[0][0])
  const positionSentRef = useRef(false)
  const questionText = useMemo(
    () => markdownQuestion(stripSsml(mayaQuestion.question)),
    [mayaQuestion]
  )

  useEffect(() => {
    const defaultValue = mayaQuestion?.default || countries[0][0]
    setOption(defaultValue)
  }, [mayaQuestion, countries])

  const submitOption = (option: string, label?: string) => {
    const info = {
      type: 'response',
      questionId: mayaQuestion.id,
      response: option,
      label: label || countries.find(([code, label]: any) => code === option)[1]
    }
    dispatch({ type: 'mayaMessage', payload: info })
    sendMessage(info)
  }

  const getPositionProps = (position: Position) => {
    if (position && position.coords) {
      return {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      }
    }
  }

  useEffect(() => {
    if (!mayaQuestion.disableLocation) {
      const getPosition = () => {
        navigator.geolocation.getCurrentPosition(
          position => {
            if (!positionSentRef.current) {
              const info = {
                type: 'response',
                questionId: mayaQuestion.id,
                response: getPositionProps(position),
                label: 'Location sent'
              }
              dispatch({ type: 'mayaMessage', payload: info })
              sendMessage(info)
              positionSentRef.current = true
            }
          },
          () => {
            setShowSelect(true)
          }
        )
        // if Location is disabled in MacOS, callbacks in getCurrentPosition
        // are never called, so if after some seconds we're still in same question,
        // we'll assume location is disabled and show countries
        setTimeout(() => {
          mayaQuestion?.format === 'country' && setShowSelect(true)
        }, 8000)
      }
      if (navigator.permissions) {
        navigator.permissions
          .query({ name: 'geolocation' })
          .then(function(result) {
            const handleGranted = () => {
              setTimeout(() => {
                // TODO: make avatar say 'Location submitted' or something like that?
                getPosition()
              }, 1000)
            }
            const handlePrompt = () => {
              getPosition()
            }
            const handleDenied = () => {
              setShowSelect(true)
            }
            const handleState = () => {
              if (result.state === 'granted') {
                handleGranted()
              } else if (result.state === 'prompt') {
                // debugger;
                handlePrompt()
              } else if (result.state === 'denied') {
                handleDenied()
              }
            }
            handleState()

            result.onchange = () => {
              handleState()
            }
          })
      } else {
        getPosition()
      }
    } else {
      setShowSelect(true)
    }
  }, [dispatch, mayaQuestion, mayaQuestion.disableLocation, mayaQuestion.id])

  const selectOption = (e: any) => {
    setOption(e.target.value)
  }
  if (!showSelect) return null

  return (
    <Flex sx={styles.questionContainer}>
      <Flex sx={styles.topContainer}>
        {!hideQuestionTitle && <Text sx={styles.question}>{questionText}</Text>}
        <Text sx={styles.instructions}>{mayaQuestion.instruction}</Text>
      </Flex>
      <Flex sx={styles.selectContainer}>
        <Select
          value={option}
          onChange={selectOption}
          sx={styles.countrySelect}
        >
          {countries.map(([code, label]: any) => (
            <Text
              as="option"
              value={code}
              key={code}
              sx={{
                bg: 'backgroundPrimary',
                text: 'color',
                fontFamily: 'inherit'
              }}
            >
              {label}
            </Text>
          ))}
        </Select>
        {mayaQuestion.optional && (
          <Button
            type="submit"
            onClick={() => submitOption('', 'Skipped')}
            sx={{ ...styles.button, mr: 3, fontWeight: 'normal' }}
            variant="whiteInverted"
          >
            {t('Question.skip')}
          </Button>
        )}
        <Button
          id="submit"
          type="submit"
          onClick={() => submitOption(option)}
          sx={styles.button}
        >
          {t('Question.submit')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default CountryQuestion
