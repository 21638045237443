import React, { useContext, useEffect, useState } from 'react'
import { Button, Flex } from 'rebass'
import { trackHandler, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { motion, AnimatePresence } from 'framer-motion'
import { styles as s } from './styles'

const MotionButton = motion.custom(Button)
const MotionFlex = motion.custom(Flex)
const styles = s.suggestedResponses

const SuggestedResponses = () => {
  const { sendText, dispatch } = useContext(UneeqContext)
  const [selected, setSelected] = useState<any>({})

  const {
    onScreenInfo: { suggestedResponses },
    hiddenUI
  } = useUneeqState()

  console.log(
    'suggestedResponses?.multiSelect',
    suggestedResponses?.multiSelect
  )

  const send = (response: string) => {
    sendText(response)
    dispatch({ type: 'suggestedResponseSent', payload: response })
  }

  const submitResponses = () => {
    const selectedResponses = Object.entries(selected)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key)

    sendText(selectedResponses)
    dispatch({ type: 'suggestedResponseSent' })
  }

  const submitDisabled = Object.values(selected).every(
    (option: any) => option === false
  )

  const toggleCheckbox = (value: boolean, response: any) => {
    if (responses.some((resp: any) => resp.exclusive)) {
      if (response.exclusive) {
        // set all others to false, except the exclusive one we selected
        setSelected({
          ...Object.fromEntries(Object.keys(selected).map(key => [key, false])),
          [response.id]: value
        })
      } else {
        // toggle selected, and also unselect exclusive
        setSelected({
          ...Object.fromEntries(
            Object.keys(selected).map(key => {
              const isExclusive = responses.find((rsp: any) => rsp.id === key)
                .exclusive
              return [key, isExclusive ? false : selected[key]]
            })
          ),
          [response.id]: value
        })
      }
    } else {
      setSelected({
        ...selected,
        [response.id]: value
      })
    }
  }

  const chosen = suggestedResponses?.chosenResponse
  const responses = suggestedResponses?.suggestedResponses

  useEffect(() => {
    let newSelected = {} as any
    if (responses) {
      responses.forEach((response: any) => (newSelected[response.id] = false))
      setSelected(newSelected)
    }
  }, [responses])

  return (
    <>
      <AnimatePresence>
        {!chosen && (
          <MotionFlex
            variants={{
              start: {
                opacity: 0,
                transform: 'translate(-100px, 0px)'
              },
              end: {
                opacity: 1,
                transform: 'translate(10px, 0px)'
              },
              final: {
                opacity: 0,
                transform: 'translate(100px, 0px)'
              }
            }}
            initial="start"
            animate="end"
            exit="final"
            sx={{
              display: hiddenUI
                ? ['none', 'none', 'none', 'none', 'flex', 'flex']
                : 'flex',
              justifyContent: [
                'left',
                'left',
                'center',
                'center',
                'center',
                'center'
              ]
            }}
          >
            <Flex sx={styles.title}>{suggestedResponses?.mainTitle}</Flex>
          </MotionFlex>
        )}
      </AnimatePresence>
      <AnimatePresence>
        <MotionFlex
          variants={{
            start: {
              transition: {
                delay: 0.8,
                staggerChildren: 0.4
              }
            },
            end: {
              transition: {
                staggerChildren: 0.4
              }
            },
            final: {
              transition: {
                duration: 0.4
              }
            }
          }}
          initial="start"
          transition={{
            duration: 0.3
          }}
          animate="end"
          exit="final"
          sx={{
            ...styles.itemsContainer,
            display: hiddenUI
              ? ['none', 'none', 'none', 'none', 'flex', 'flex']
              : 'flex'
          }}
          key={suggestedResponses?.questionId}
        >
          <AnimatePresence>
            {responses?.map(
              (response: any) =>
                (!chosen || response.id === chosen) &&
                (suggestedResponses?.multiSelect ? (
                  <MotionFlex
                    transition={{ duration: 0.4 }}
                    variants={{
                      start: {
                        opacity: 0,
                        transform: 'translate(-200px, 0px)',
                        transition: {
                          duration: 0.4
                        }
                      },
                      end: {
                        opacity: 1,
                        transform: 'translate(1px, 0px)'
                      },
                      final: {
                        opacity: 0,
                        transform: 'translate(200px, 0px)'
                      }
                    }}
                    // initial="start"
                    // animate="end"
                    exit="final"
                    key={response.id}
                    sx={{
                      ...styles.item,
                      cursor: chosen ? 'auto' : 'pointer'
                    }}
                  >
                    <Button
                      variant="primaryInverted"
                      onClick={() =>
                        toggleCheckbox(!selected[response.id], response)
                      }
                      sx={{
                        backgroundColor: selected[response.id]
                          ? 'primary'
                          : 'black'
                      }}
                    >
                      {response.label}
                    </Button>
                  </MotionFlex>
                ) : (
                  <MotionButton
                    transition={{
                      duration: 0.4
                    }}
                    variant="primaryInverted"
                    variants={{
                      start: {
                        opacity: 0,
                        transform: 'translate(-200px, 0px)',
                        transition: {
                          duration: 0.4
                        }
                      },
                      end: {
                        opacity: 1,
                        transform: 'translate(1px, 0px)'
                      },
                      final: {
                        opacity: 0,
                        transform: 'translate(200px, 0px)'
                      }
                    }}
                    // initial="start"
                    // animate="end"
                    exit="final"
                    key={response.id}
                    sx={{
                      ...styles.item,
                      cursor: chosen ? 'auto' : 'pointer'
                    }}
                    onClick={
                      !chosen
                        ? trackHandler(
                            () => send(response.id),
                            'suggested-response-btn'
                          )
                        : () => {}
                    }
                  >
                    {response.label}
                  </MotionButton>
                ))
            )}
          </AnimatePresence>
          {suggestedResponses?.multiSelect && (
            <Button
              onClick={submitResponses}
              disabled={submitDisabled}
              sx={styles.submitButton}
            >
              Submit
            </Button>
          )}
        </MotionFlex>
      </AnimatePresence>
    </>
  )
}

export default SuggestedResponses
