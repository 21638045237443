import React, { useContext, useEffect, useState, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { stripSsml, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { useSendSocketMessage } from '../../../app/hooks/useSendSocketMessage'
import { isTablet, isMobileOnly } from 'react-device-detect'
import { Button, Flex, Text, Box } from 'rebass'
import styles from '../styles'
import { ReactComponent as ChevronDown } from '../down-chevron.svg'
import useWidgetContext from '../../../app/hooks/useWidgetContext'
import { markdownQuestion } from '../Question'
import { fnOnEnter } from '../../../utils'

const Check = `data:image/svg+xml;utf8,<svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.8945 1.04688L4.70312 8.23828L2.07812 5.58594C1.94141 5.47656 1.72266 5.47656 1.61328 5.58594L0.820312 6.37891C0.710938 6.48828 0.710938 6.70703 0.820312 6.84375L4.48438 10.4805C4.62109 10.6172 4.8125 10.6172 4.94922 10.4805L13.1523 2.27734C13.2617 2.16797 13.2617 1.94922 13.1523 1.8125L12.3594 1.04688C12.25 0.910156 12.0312 0.910156 11.8945 1.04688Z" fill="white"/>
</svg>`

const MultiSelectQuestion = () => {
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()
  const sendMessage = useSendSocketMessage()
  const { mayaQuestion, hideQuestionTitle } = useUneeqState()
  const { widgetMode, fullscreen } = useWidgetContext()
  const scrollableContainerRef = useRef<HTMLElement>(null)

  const [selected, setSelected] = useState<any>({})
  const isTouchScreen = isTablet || isMobileOnly
  const questionText = useMemo(
    () => markdownQuestion(stripSsml(mayaQuestion.question)),
    [mayaQuestion]
  )
  const [showArrow, setShowArrow] = useState(false)

  useEffect(() => {
    if (scrollableContainerRef.current) {
      const { scrollHeight, clientHeight } = scrollableContainerRef.current

      if (scrollHeight > clientHeight) {
        scrollableContainerRef.current.scrollTop = 0
        setShowArrow(true)
      }
      const containerRef = scrollableContainerRef.current
      const hideArrowFn = () => {
        setShowArrow(false)
      }
      containerRef.addEventListener('scroll', hideArrowFn)

      return () => containerRef.removeEventListener('scroll', hideArrowFn)
    }
  }, [setShowArrow, mayaQuestion])

  const submitOption = (selectedAnswers: any) => {
    const label = mayaQuestion.options
      .filter((opt: any) => selectedAnswers[opt.id])
      .map((opt: any) => opt.label)
      .join(', ')
    const response = {
      type: 'response',
      questionId: mayaQuestion.id,
      responses: Object.entries(selectedAnswers)
        .filter(([key, value]: any) => value === true)
        .map(([key, value]: any) => key.toString()),
      label: label || t('Transcript.skippedQuestion')
    }
    dispatch({ type: 'mayaMessage', payload: response })
    sendMessage(response)
  }
  const toggleCheckbox = (response: any) => {
    if (mayaQuestion.options.some((resp: any) => resp.exclusive)) {
      if (response.exclusive) {
        // set all others to false, except the exclusive one we selected
        const selectedAnswers = {
          ...Object.fromEntries(Object.keys(selected).map(key => [key, false])),
          [response.id]: !selected[response.id]
        }
        setSelected(selectedAnswers)
        submitOption(selectedAnswers)
      } else {
        // toggle selected, and also unselect exclusive
        setSelected({
          ...Object.fromEntries(
            Object.keys(selected).map(key => {
              const isExclusive = mayaQuestion.options.find(
                (rsp: any) => rsp.id === key.toString()
              ).exclusive
              return [key, isExclusive ? false : selected[key]]
            })
          ),
          [response.id]: !selected[response.id]
        })
      }
    } else {
      setSelected({
        ...selected,
        [response.id]: !selected[response.id]
      })
    }
  }

  useEffect(() => {
    if (mayaQuestion.options) {
      const preselectedOptions = mayaQuestion.value || []
      const newSelected = mayaQuestion.options.reduce(
        (options: Record<string, boolean>, response: any) => {
          options[response.id] = preselectedOptions.includes(response.id)
          return options
        },
        {}
      )
      setSelected(newSelected)
    }
  }, [mayaQuestion])

  const noValueSelected = Object.values(selected).every(val => val === false)

  const submitButton = () => {
    if (!mayaQuestion.optional || !noValueSelected) {
      return (
        <Button
          id="submit"
          type="submit"
          disabled={noValueSelected}
          onClick={() => submitOption(selected)}
          variant="multiSelectSubmitButton"
        >
          {t('Question.submit')}
        </Button>
      )
    }

    return (
      <Button
        type="submit"
        onClick={() => submitOption([])}
        variant="multiSelectSubmitButton"
      >
        {t('Question.skip')}
      </Button>
    )
  }

  return (
    <Flex sx={{ ...styles.questionContainer }}>
      <Flex sx={styles.topContainer}>
        {!hideQuestionTitle && <Text sx={styles.question}>{questionText}</Text>}
        <Text sx={styles.instructions}>{mayaQuestion.instruction}</Text>
      </Flex>
      <Box
        id="options-container"
        ref={scrollableContainerRef}
        sx={{
          ...styles.multiSelectOptionsContainer,
          ...(widgetMode && !fullscreen
            ? styles.widgetMultiSelectOptionsContainer
            : {}),
          display: 'block'
        }}
      >
        {mayaQuestion.options &&
          mayaQuestion.options.map((option: any) => {
            const borderColor = selected[option.id]
              ? 'optionSelectedBorder'
              : 'optionBorder'
            const bgHoverColor = 'inputBackgroundHover'
            return (
              <Button
                variant="select"
                sx={{
                  borderColor: borderColor,
                  bg: selected[option.id] && 'optionSelectedBg',
                  fill: 'borderColor',
                  backgroundImage: selected[option.id] && `url('${Check}') `,
                  backgroundSize: '4%',
                  backgroundPosition: '98% center',
                  backgroundRepeat: 'no-repeat',
                  textAlign: 'left',
                  justifyContent: 'flex-start',
                  '&:hover': {
                    background: selected[option.id]
                      ? 'optionSelectedBg'
                      : isTouchScreen
                      ? 'transparent'
                      : bgHoverColor,
                    borderColor: borderColor
                  },
                  '&:focus': {
                    bg: selected[option.id]
                      ? 'optionSelectedBg'
                      : 'transparent',
                    borderColor: 'optionSelectedBorderHover'
                  }
                }}
                onClick={() => toggleCheckbox(option)}
                key={option.id}
              >
                {option.label}
              </Button>
            )
          })}
      </Box>

      {showArrow && (
        <Box sx={styles.arrowDownContainer}>
          <Text variant="unstyled" sx={styles.arrowDown}>
            <ChevronDown />
          </Text>
        </Box>
      )}
      {submitButton()}
    </Flex>
  )
}

export default MultiSelectQuestion
