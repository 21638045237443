export const styles = {
  font: {
    fontSize: '38px',
    lineHeight: '46px',
    fontWeight: 700,
    color: 'white'
  },
  button: {
    padding: '16px',
    bg: 'backgroundSecondary',
    p: '20px',
    textAlign: 'start',
    mt: '16px',
    border: '2px solid rgba(255, 255, 255, 0.1)',
    cursor: 'pointer',
    transition: 'background-color .4s',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:focus': {
      bg: 'white',
      color: 'backgroundPrimary'
    }
  },
  container: (active: boolean) => ({
    flexDirection: 'column',
    bg: active ? 'backgroundPrimary' : '#32373A',
    opacity: active ? 1 : 0.4,
    alignSelf: 'center',
    justifySelf: 'start',
    borderRadius: '8px',
    p: '32px',
    zIndex: 1
  })
}
