import React, { useEffect, useRef } from 'react'
import { Flex, Text, Box, Button } from 'rebass'
import { Input, Label } from '@rebass/forms'
import { styles } from './taskListStyles'
import { ReactComponent as CheckIcon } from './check.svg'
import { ReactComponent as WellnessCheckIcon } from './wellness-checkin.svg'
import { Todo } from '../../types/Todo'
import formatRelative from 'date-fns/formatRelative'
import { motion, AnimatePresence } from 'framer-motion'
import { endOfDay, startOfDay } from 'date-fns'
import { enUS } from 'date-fns/locale'
import { useApplicationState } from '../../../hooks/useApplicationState'
import { useTvContext } from '../../../app/hooks/useTvContext'
import { fnOnEnter } from '../../../utils'

type TaskListProps = {
  title: string
  subtitle: string
  active: boolean
  setActive: () => void
  todoList: Todo[] | null
  toggleCheck: (checked: boolean, id: string) => void
  animateRight?: boolean
  setWellnessCheckInTodoId?: (id: string) => void
  onWellnessCheck: () => void
  focusableClass: string
}
export const TaskList = React.memo(
  ({
    title,
    subtitle,
    active,
    setActive,
    todoList,
    toggleCheck,
    animateRight,
    setWellnessCheckInTodoId,
    onWellnessCheck,
    focusableClass
  }: TaskListProps) => {
    const MotionLabel = motion.custom(Label)

    console.log('todoList', todoList)

    const { currentLanguage } = useApplicationState({})

    const shortLanguage = currentLanguage.includes('-')
      ? currentLanguage.split('-')[0]
      : currentLanguage

    const firstItemRef = useRef<HTMLElement>(null)
    const taskContainer = useRef<HTMLDivElement>(null)

    const getTodoTitle = (todo: Todo) => {
      const todoTitle =
        todo.todo?.name[shortLanguage] || todo.title || todo.todo?.title
      const isWellnessCheckTodo =
        todo?.todo?.title.includes('Check In') ||
        todoTitle?.includes('Check In')!
      const parameterTodoSpanishTranslations = {
        'Check your weight.': 'Revise peso.',
        'Check your pulse.': 'Revise ritmo cardíaco.',
        'Check your oxygen saturation.': 'Revise saturación de oxígeno.',
        'Check your blood pressure.': 'Revise presión arterial.',
        'Check your blood glucose level': 'Revise nivel de glucosa en sangre.'
      }
      if (shortLanguage === 'es') {
        if (isWellnessCheckTodo) {
          return `Comenzar ${todoTitle}`
        }
        if (todo.type === 'parameterTodo') {
          // @ts-ignore
          return parameterTodoSpanishTranslations[todo.title]
        }
        if (todo.type === 'medication') {
          // @ts-ignore
          return `Tome su ${todo.medicationSchedule.medicineName}`
        }
      }
      if (isWellnessCheckTodo) return `Start ${todoTitle}`
      return todo.todo?.name[shortLanguage] || todo.title || todo.todo?.title
    }

    const today = new Date()

    const formatRelativeDate = (
      date: any,
      baseDate: any,
      fullDate: boolean = true
    ) => {
      let formatRelativeLocale: any = {
        lastWeek: "'last' eeee p",
        yesterday: "'yesterday' p",
        today: "'today' p",
        tomorrow: "'tomorrow' p",
        nextWeek: 'eeee p',
        other: 'P'
      }

      if (shortLanguage === 'es') {
        formatRelativeLocale = {
          lastWeek: "'el' eeee 'pasado' p",
          yesterday: "'ayer' p",
          today: "'hoy' p",
          tomorrow: "'mañana' p",
          nextWeek: 'eeee p',
          other: 'P'
        }
      }

      let formatRelativeLocaleDateOnly: any = {
        lastWeek: "'last' eeee",
        yesterday: "'yesterday'",
        today: "'today'",
        tomorrow: "'tomorrow'",
        nextWeek: 'eeee',
        other: 'P'
      }

      if (shortLanguage === 'es') {
        formatRelativeLocaleDateOnly = {
          lastWeek: "'el' eeee 'pasado'",
          yesterday: "'ayer'",
          today: "'hoy'",
          tomorrow: "'mañana'",
          nextWeek: 'eeee',
          other: 'P'
        }
      }

      const locale = {
        ...enUS,
        formatRelative: (token: any) =>
          fullDate
            ? formatRelativeLocale[token]
            : formatRelativeLocaleDateOnly[token]
      }
      return formatRelative(date, baseDate, { locale })
    }

    const toggleItem = (e: any, todo: any) => {
      // we're setting the same value because it has already changed (onChange event)
      toggleCheck(e.target.checked, todo._id)
    }

    return (
      <Flex
        onClick={setActive}
        sx={styles.card(active)}
        ref={taskContainer}
        className="focusable"
        onFocus={(e: any) => {
          // set current section as active
          setActive()
          // only set focus on 1st element if the onFocus was triggered in container div
          if (
            firstItemRef.current &&
            taskContainer.current &&
            e.target === taskContainer.current
          ) {
            setTimeout(() => {
              firstItemRef.current?.focus()
            }, 300)
          }
        }}
      >
        <Box sx={styles.header}>
          <Text sx={styles.title}>{title}</Text>
          <Text sx={styles.subtitle}>{subtitle}</Text>
        </Box>
        <Flex as="form" sx={styles.list}>
          <AnimatePresence>
            {todoList &&
              todoList
                .filter(
                  item =>
                    new Date(item._completeBy) >= startOfDay(today) &&
                    new Date(item._completeBy) <= endOfDay(today)
                )
                .sort((a, b) => {
                  return (
                    new Date(a._completeBy).valueOf() -
                    new Date(b._completeBy).valueOf()
                  )
                })
                .map((todo, index) => (
                  <MotionLabel
                    sx={styles.label}
                    key={todo._id}
                    transition={{ duration: 0.6 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, x: animateRight ? '100%' : '-100%' }}
                  >
                    {todo?.todo?.title.includes('Check In') ? (
                      <Box sx={styles.inputContainer}>
                        <Flex tabIndex={-1} sx={styles.wellnessCheck}>
                          <WellnessCheckIcon />
                        </Flex>
                        <Button
                          className={focusableClass}
                          type="button"
                          ref={index === 0 ? firstItemRef : undefined}
                          id={todo._id}
                          onClick={e => {
                            e.preventDefault()
                            setWellnessCheckInTodoId &&
                              setWellnessCheckInTodoId(todo._id)
                            onWellnessCheck()
                          }}
                          variant="unstyled"
                          sx={{ ...styles.wellnessCheckinButton }}
                        >
                          {getTodoTitle(todo)}
                        </Button>
                      </Box>
                    ) : (
                      <Box sx={styles.inputContainer}>
                        <Input
                          className={focusableClass}
                          type="checkbox"
                          id={todo._id}
                          ref={index === 0 ? firstItemRef : undefined}
                          name={getTodoTitle(todo)}
                          defaultChecked={todo.status === 'completed'}
                          onChange={e => toggleItem(e, todo)}
                          onKeyDown={
                            // we're setting the checkbox with the opposite value than currently
                            fnOnEnter((e: any) =>
                              toggleCheck(!e.target.checked, todo._id)
                            )
                          }
                          onFocus={setActive}
                          style={{
                            position: 'absolute',
                            opacity: 0
                          }}
                        />
                        <Flex sx={styles.checkbox}>
                          <CheckIcon />
                        </Flex>
                        <Flex sx={styles.checkboxTextContainer}>
                          <Text sx={styles.checkboxTitle}>
                            {getTodoTitle(todo)}
                          </Text>
                          <Text sx={styles.checkboxSubtitle}>
                            {formatRelativeDate(
                              new Date(todo._completeBy),
                              today,
                              todo.type === 'medication'
                            )}
                          </Text>
                        </Flex>
                      </Box>
                    )}
                  </MotionLabel>
                ))}
          </AnimatePresence>
        </Flex>
      </Flex>
    )
  }
)
