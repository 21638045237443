import React, { useContext } from 'react'
import { Box, Button, Flex, Text } from 'rebass'
import { ReactComponent as ExclamationIcon } from '../../app/assets/icons/error.svg'
import { styles as s } from './styles'
import { UneeqContext } from 'uneeq-react-core'
import { MayaOverlay } from '../MayaOverlay'
import { useTranslation } from 'react-i18next'
const styles = s.error

interface ErrorProps {
  message?: string
}
const Error: React.FC<ErrorProps> = ({ message }) => {
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()
  const clearError = () => dispatch({ type: 'clearError' })
  return (
    <MayaOverlay>
      <Flex sx={styles.container}>
        <Box sx={{ pt: 8, pb: 12 }}>
          <ExclamationIcon />
        </Box>

        <Text variant="title" sx={styles.heading}>
          Error
        </Text>
        <Text sx={styles.message}>{message || t('Error.serverError')}</Text>
        <Flex sx={styles.divider} />
        <Flex>
          <Button
            sx={styles.closeButton}
            variant="alertPrimary"
            onClick={clearError}
          >
            {t('Error.close')}
          </Button>
        </Flex>
      </Flex>
    </MayaOverlay>
  )
}

export default Error
