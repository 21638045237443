import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  UneeqContext,
  useIsSmallScreen,
  usePortraitScreen
} from 'uneeq-react-core'
import { useTranslation } from 'react-i18next'
import { Box } from 'rebass'
import Hls from 'hls.js'
import mux from 'mux-embed'
interface LoadingVideoProps {
  onFinishVideo?: Function
  widgetMode?: boolean
  forceMp4?: boolean
}

const initializeMetrics = (videoRef: React.RefObject<HTMLVideoElement>) => {
  // @ts-ignore
  mux.monitor(videoRef.current, {
    debug: false,
    data: {
      // TODO: add to vercel .env
      env_key: process.env.REACT_APP_MUX_ENV || '5j9vdn4r8de8ubj30tdiknn8i',

      // Metadata
      player_name: 'HTML5 with HLSJS', // ex: 'My Main Player'
      player_init_time: Date.now(), // ex: 1451606400000
      video_title: 'Introduction Video'
      // ... and other metadata
    }
  })
}

const getVideoStyles = (
  isSmallScreen: boolean,
  isPortraitScreen: boolean,
  widgetMode: boolean,
  videoWidth: number,
  shift: number
) => {
  if (widgetMode) {
    return {
      width: videoWidth,
      height: '100%',
      marginLeft: shift
    }
  }

  if (isSmallScreen || isPortraitScreen) {
    return {
      width: videoWidth,
      height: '100vh',
      marginLeft: shift
    }
  }

  return {
    width: '100vw',
    height: '100vh',
    marginLeft: 0
  }
}

const muxVideos: Record<
  string,
  {
    videoSrc: string
    mp4VideoSrc: string
    poster: string
    avatarPosition: number
  }
> = {
  en: {
    videoSrc:
      'https://stream.mux.com/CX3T4o1Cr6yuXCvnzgmSMz3mrQH5r66hxSPJzU9xCDc.m3u8',
    mp4VideoSrc:
      'https://stream.mux.com/CX3T4o1Cr6yuXCvnzgmSMz3mrQH5r66hxSPJzU9xCDc/high.mp4',
    poster:
      'https://image.mux.com/CX3T4o1Cr6yuXCvnzgmSMz3mrQH5r66hxSPJzU9xCDc/thumbnail.png?time=0.0',
    avatarPosition: 0.645
  },
  es: {
    videoSrc:
      'https://stream.mux.com/VG5TOt7XRcGz6i49LwglLUUhh3XiRF7YRNOuD02kFB3M.m3u8',
    mp4VideoSrc:
      'https://stream.mux.com/VG5TOt7XRcGz6i49LwglLUUhh3XiRF7YRNOuD02kFB3M/high.mp4',
    poster:
      'https://image.mux.com/VG5TOt7XRcGz6i49LwglLUUhh3XiRF7YRNOuD02kFB3M/thumbnail.png?time=0.0',
    avatarPosition: 0.49
  }
}

const getMuxVideoSource = (i18nLanguage: string) => {
  const language = i18nLanguage?.toLowerCase()?.includes('-')
    ? i18nLanguage?.split('-')[0]
    : i18nLanguage
  return muxVideos[language] ? muxVideos[language] : muxVideos['en']
}

const LoadingVideo = React.memo(
  ({
    onFinishVideo,
    widgetMode = false,
    forceMp4 = false
  }: LoadingVideoProps) => {
    const isSmallScreen = useIsSmallScreen()
    const isPortraitScreen = usePortraitScreen()
    const { config } = useContext(UneeqContext)
    const heightRef = useRef<HTMLElement>(null)
    const videoRef = useRef<HTMLVideoElement>(null)
    const [videoWidth, setVideoWidth] = useState(0)
    const [shift, setShift] = useState(0)
    const { i18n } = useTranslation()

    const muxSource = useMemo(() => getMuxVideoSource(i18n.language), [
      i18n.language
    ])

    useEffect(() => {
      if (videoRef.current) {
        if (forceMp4) {
          videoRef.current.src = muxSource.mp4VideoSrc
          return
        }
        // TODO: move to .env?
        initializeMetrics(videoRef)
        const videoSrc = muxSource.videoSrc
        const mp4VideoSrc = muxSource.mp4VideoSrc
        if (Hls.isSupported()) {
          var hls = new Hls()
          hls.loadSource(videoSrc)
          hls.attachMedia(videoRef.current)
        }
        // hls.js is not supported on platforms that do not have Media Source
        // Extensions (MSE) enabled.
        //
        // When the browser has built-in HLS support (check using `canPlayType`),
        // we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video
        // element through the `src` property. This is using the built-in support
        // of the plain video element, without using hls.js.
        //
        // Note: it would be more normal to wait on the 'canplay' event below however
        // on Safari (where you are most likely to find built-in HLS support) the
        // video.src URL must be on the user-driven white-list before a 'canplay'
        // event will be emitted; the last video event that can be reliably
        // listened-for when the URL is not on the white-list is 'loadedmetadata'.
        else if (
          videoRef.current.canPlayType('application/vnd.apple.mpegurl')
        ) {
          videoRef.current.src = videoSrc
        } else {
          videoRef.current.src = mp4VideoSrc
        }
      }
    }, [isPortraitScreen, isSmallScreen, muxSource, forceMp4])

    useEffect(() => {
      if (heightRef.current) {
        const { width, height } = heightRef.current.getBoundingClientRect()
        if (
          width &&
          height &&
          (isSmallScreen || widgetMode || isPortraitScreen)
        ) {
          const aspectRatioWidth = (height * 16) / 9 // full width of the video at this height
          const avatarLeft = aspectRatioWidth * muxSource.avatarPosition // where the avatar is within video
          const center = width / 2 // the center of the frame - where we want the avatar to be

          const shiftToCenter = Math.min(0, center - avatarLeft)

          setShift(shiftToCenter) // How far to shift the video over to center
          setVideoWidth(
            aspectRatioWidth +
              Math.max(0, width - aspectRatioWidth - shiftToCenter)
          ) // If remains a black space in the right we zoom in to fill it with the video.
        }
      }
    }, [isPortraitScreen, isSmallScreen, widgetMode, muxSource])

    const handleEndedVideo = () => {
      if (onFinishVideo) {
        onFinishVideo()
      }
    }

    const videoStyles = getVideoStyles(
      isSmallScreen,
      isPortraitScreen,
      widgetMode,
      videoWidth,
      shift
    )

    return (
      <Box
        sx={{
          height: widgetMode ? '100%' : '100vh',
          zIndex: 10,
          backgroundColor: '#727172'
        }}
        ref={heightRef}
      >
        <video
          id="introductionVideo"
          ref={videoRef}
          playsInline
          autoPlay
          poster={muxSource.poster}
          onEnded={handleEndedVideo}
          onPauseCapture={() => {
            // Mobile devices stop playing video after the DH video is fully loaded.
            if (videoRef.current) {
              if (!videoRef.current.ended) {
                videoRef.current.play()
              }
            }
          }}
          style={{
            ...videoStyles,
            objectFit: 'cover'
          }}
        ></video>
      </Box>
    )
  }
)

export default LoadingVideo
