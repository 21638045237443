const styles = {
  container: {
    width: 'auto',
    height: '100%',
    p: 2,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: 'backgroundSecondary',
    borderRadius: 'buttonBig',
    cursor: 'pointer',
    minWidth: 'unset',
    transition: 'all .2s',
    '& svg': {
      minWidth: 16,
      minHeight: 16,
      margin: 0,
      stroke: 'text'
    },
    '&:hover': {
      backgroundColor: 'backgroundSecondaryHover'
    }
  }
}

export default styles
