const closeSessionMiddleware = (config?: any) => (
  state: any,
  action: any,
  uneeq: any,
  context: any
) => {
  if (action?.type === 'mayaMessage' && action?.payload?.type === 'end') {
    const { restartSession } = context
    config.endMayaSession()
    restartSession()
  }
}

export default closeSessionMiddleware
