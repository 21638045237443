import React from 'react'
import { Flex } from 'rebass'
import styles from './styles'
import { trackHandler } from 'uneeq-react-core'
import { ReactComponent as ExitIcon } from './exit.svg'

interface MayaMenuProps {
  onClick: () => void
  customStyles?: any
}
const MayaCloseButton: React.FC<MayaMenuProps> = ({
  onClick,
  customStyles
}) => {
  const containerStyles = {
    ...styles.container,
    ...customStyles
  }
  return (
    <Flex
      sx={containerStyles}
      onClick={trackHandler(onClick, 'exit-session-btn')}
    >
      <ExitIcon />
    </Flex>
  )
}

export default MayaCloseButton
