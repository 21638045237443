import { Uneeq } from 'uneeq-js'

const runMiddleware = (
  middleware: any[],
  state: any,
  action: any,
  uneeq: Uneeq,
  context: any
) => {
  middleware.forEach(middleware => middleware(state, action, uneeq, context))
}

export default runMiddleware
