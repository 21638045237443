import React from 'react'
import { Button, Text, Flex, Heading, Box } from 'rebass'
import styles from './styles'
import { trackHandler } from 'uneeq-react-core'
import { ReactComponent as ExclamationIcon } from '../../app/assets/icons/error.svg'
import { MayaOverlay } from '../MayaOverlay'
import { useTranslation } from 'react-i18next'

interface FatalErrorProps {
  errorTitle?: string
  errorMessage?: string
  clearError?: () => void
  buttonText?: string
  children?: React.ReactNode
}
const FatalError: React.FC<FatalErrorProps> = ({
  errorTitle = 'Error',
  errorMessage,
  clearError,
  buttonText,
  children
}) => {
  const { t } = useTranslation()
  return (
    <MayaOverlay>
      <Flex
        sx={{
          ...styles.error.container,
          p: 10
        }}
      >
        <Box sx={styles.fatal.icon}>
          <ExclamationIcon />
        </Box>

        <Heading variant="title" sx={styles.fatal.heading}>
          {errorTitle}
        </Heading>
        {!children && (
          <Text sx={styles.fatal.message}>
            {errorMessage || t('Error.serverError')}
          </Text>
        )}
        {children !== undefined && (
          <Flex sx={styles.fatal.childrenContainer}>{children}</Flex>
        )}
        {clearError && (
          <Button
            sx={styles.fatal.backButton}
            variant="primary"
            onClick={trackHandler(clearError, 'close-error-btn')}
          >
            {buttonText || t('Error.backToHomepage')}
          </Button>
        )}
      </Flex>
    </MayaOverlay>
  )
}

export default FatalError
