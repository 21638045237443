const loadingGatewayReducer = (config?: any) => (state: any, action: any) => {
  switch (action.type) {
    case 'approved':
      return { ...state, loadingPercentage: 10 }
    case 'uneeqMessageReady':
      return { ...state, loadingPercentage: 25 }
    case 'uneeqMessageDevicePermissionAllowed':
      return { ...state, loadingPercentage: 29 }
    case 'uneeqMessageSetMicSuccess':
      return { ...state, loadingPercentage: 33 }
    case 'uneeqMessageDeviceListUpdated':
      return { ...state, loadingPercentage: 55 }
    case 'uneeqMessageAvatarAvailable':
      return { ...state, loadingPercentage: 68 }
    case 'uneeqMessageSetSpeakerSuccess':
      return { ...state, loadingPercentage: 73 }
    case 'uneeqMessageClientMediaStreamUpdate':
      return {
        ...state,
        loadingPercentage:
          state.loadingPercentage < 85 ? 85 : state.loadingPercentage
      }
    case 'uneeqMessageSessionPaused':
      return {
        ...state,
        sessionPaused: true,
        hideQuestionTitle: false
      }
    case 'uneeqMessageSessionResumed':
      return {
        ...state,
        sessionPaused: false,
        hideQuestionTitle:
          state.speak &&
          (state.mayaConfig?.hideQuestion || state.mayaQuestion?.hideQuestion)
      }
    case 'uneeqMessageSessionLive':
      return {
        ...state,
        ready: state.socketConnected ? true : state.ready,
        loadingPercentage: state.loadingPercentage === 90 ? 100 : 90
      }
    case 'socketConnected':
      return {
        ...state,
        socketConnected: true,
        ready: state.loadingPercentage >= 90 ? true : state.ready
      }
    case 'setSpeak':
      return {
        ...state,
        ready: action.payload ? state.ready : true
      }
    case 'loadingVideoActive': {
      return {
        ...state,
        loadingVideoActive: true
      }
    }
    case 'loadingVideoFinished': {
      return {
        ...state,
        loadingVideoActive: false
      }
    }
    default:
      return state
  }
}

export default loadingGatewayReducer
