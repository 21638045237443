const isLowBandwidth = (
  startDatetime: Date,
  finishDatetime: Date,
  maxLoadingTimeInMs: number = 1000
) => {
  const time = finishDatetime.getTime() - startDatetime.getTime()

  return time > maxLoadingTimeInMs
}

const mayaBandwidthReducer = (maxLoadingTimeInMs: number) => (
  state: any,
  action: any
) => {
  switch (action.type) {
    case 'approved':
      return {
        ...state,
        startLoadingAt: new Date()
      }
    case 'uneeqMessageSessionLive':
      const finishLoading = new Date()
      const lowBandwidth = isLowBandwidth(
        state.startLoadingAt,
        finishLoading,
        maxLoadingTimeInMs
      )
      return {
        ...state,
        finishLoadingAt: finishLoading,
        isLowBandwidth: lowBandwidth
      }
    default:
      return state
  }
}

export default mayaBandwidthReducer
