import React, { useMemo } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import HospitalCard from './HospitalCard'
import { Hospital } from '../types/Hospital'
import { Position } from '../types/Position'

interface HospitalCarouselProps {
  selected: Hospital
  setSelected: (hospital: Hospital) => void
  hospitals: Hospital[]
  userLocation: Position
}
const HospitalCarousel = React.memo(
  ({
    selected,
    setSelected,
    hospitals,
    userLocation
  }: HospitalCarouselProps) => {
    const selectedItem = useMemo(
      () => hospitals.findIndex(h => h?.name === selected?.name),
      [selected, hospitals]
    )

    const setHospitalSelected = (index: number) => {
      setSelected([...hospitals][index])
    }

    return (
      <Carousel
        selectedItem={selectedItem}
        onChange={setHospitalSelected}
        onClickItem={setHospitalSelected}
        centerMode
        centerSlidePercentage={80}
        infiniteLoop={false}
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={false}
      >
        {hospitals.map((hospital, i) => (
          <HospitalCard
            key={i}
            hospital={hospital}
            userLocation={userLocation}
            sx={{
              ...(i === 0 ? { ml: [6, 6, 6, 6, 10, 10] } : {}),
              ...(i === hospitals.length - 1
                ? { mr: [6, 6, 6, 6, 10, 10] }
                : {})
            }}
          />
        ))}
      </Carousel>
    )
  }
)

export default HospitalCarousel
