const sessionIDReducer = (state: any, action: any) => {
  if (action?.type === 'mayaMessage') {
    if (action?.payload?.session) {
      return {
        ...state,
        session: action.payload.session
      }
    }
  }

  return state
}

export default sessionIDReducer
