const styles = {
  container: {
    '& svg': {
      minWidth: 16,
      height: 16,
      margin: 0
    }
  }
}

export default styles
