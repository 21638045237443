const mayaConfigReducer = (config?: any) => (state: any, action: any) => {
  switch (action.type) {
    case 'setConfig':
      return {
        ...state,
        mayaConfig: {
          ...state.mayaConfig,
          ...action.payload
        },
        ready: action.payload.disableDigitalHuman ? true : state.ready
      }
    case 'setSpeak':
      return { ...state, speak: action.payload }
    default:
      return state
  }
}

export default mayaConfigReducer
