import React from 'react'
import { Button, Text, Flex, Heading, Box } from 'rebass'
import styles from './styles'
import { trackHandler } from 'uneeq-react-core'
import Overlay from '../generic/Overlay'
import { ReactComponent as ExclamationIcon } from '../../assets/img/exclamation.svg'
import { useTranslation } from 'react-i18next/'

interface FatalErrorProps {
  errorTitle?: string
  errorMessage?: string
  clearError?: () => void
  children?: React.ReactNode
}
const FatalError: React.FC<FatalErrorProps> = ({
  errorTitle = 'Error',
  errorMessage,
  clearError,
  children
}) => {
  const { t } = useTranslation()

  return (
    <Overlay>
      <Box variant="modal" sx={styles.fatal.container}>
        <Flex sx={styles.fatal.icon}>
          <ExclamationIcon />
        </Flex>

        <Heading sx={styles.fatal.heading}>{errorTitle}</Heading>
        {!children && (
          <Text sx={styles.fatal.message}>
            {errorMessage || t('Error.serverError')}
          </Text>
        )}
        {children !== undefined && (
          <Flex sx={styles.fatal.childrenContainer}>{children}</Flex>
        )}
        {clearError && (
          <Button
            sx={styles.fatal.backButton}
            variant="secondaryInverted"
            onClick={trackHandler(clearError, 'close-error-btn')}
          >
            {t('Error.backToHomepage')}
          </Button>
        )}
      </Box>
    </Overlay>
  )
}

export default FatalError
