import { useContext } from 'react'
import { Todo } from '../../TvApp/types/Todo'
import TvContext from '../contexts/TvContext'

export const useTvContext = () => {
  const tvContext = useContext(TvContext)

  return tvContext
    ? tvContext
    : {
        tvAppMode: false,
        wellnessCheckInTodoId: '',
        setWellnessCheckInTodoId: () => {},
        symptomIds: [],
        todoList: [],
        setTodoList: (todoList: Todo[] | null) => {},
        setSymptomIds: () => {},
        session: ''
      }
}
