const mayaMapReducer = (config?: any) => (state: any, action: any) => {
  switch (action.type) {
    case 'clearMap': {
      return {
        ...state,
        showMap: false,
        hideQuestion: false,
        hideInformation: false
      }
    }

    case 'mayaMessage': {
      switch (action?.payload?.type) {
        case 'map': {
          const { userLocations, facilities, title } = action.payload
          return {
            ...state,
            showMap: true,
            hideInformation: true,
            hideQuestion: true,
            mapInfo: {
              userLocations,
              hospitals: facilities,
              title
            }
          }
        }

        default: {
          return state
        }
      }
    }
    default:
      return state
  }
}

export default mayaMapReducer
