const pauseSessionReducer = (config?: any) => (state: any, action: any) => {
  switch (action.type) {
    case 'pauseSession':
      return {
        ...state,
        sessionPaused: true
      }
    case 'resumeSession':
      return {
        ...state,
        sessionPaused: false
      }
    default:
      return state
  }
}

export default pauseSessionReducer
