import { useEffect } from 'react'

type UseScrollToTopProps = {
  condition: () => boolean
}
export const useScrollToTop = ({
  condition = () => true
}: UseScrollToTopProps) => {
  useEffect(() => {
    const scrollToTop = () => {
      if (condition()) {
        document.documentElement.scrollTop = 0
      }
    }
    scrollToTop()

    // @ts-ignore
    window.visualViewport?.addEventListener('scrollEnd', scrollToTop)
    // @ts-ignore
    return window.visualViewport?.removeEventListener('scrollEnd', scrollToTop)
  })
}
