import { useEffect } from 'react'
import { useUneeqState } from 'uneeq-react-core'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'
import useMayaConnect from './useMayaConnect'
import { useTvContext } from '../../app/hooks/useTvContext'

const MayaUi = ({ speak }) => {
  useMayaConnect()
  const sendMessage = useSendSocketMessage()
  const { ready } = useUneeqState()
  const { symptomIds } = useTvContext()

  useEffect(() => {
    if (ready)
      sendMessage({
        type: 'begin',
        speak,
        symptomIds:
          Array.isArray(symptomIds) && symptomIds.length !== 0
            ? symptomIds
            : undefined
      })
  }, [speak, ready, sendMessage, symptomIds])

  if (!ready) return null
  // <Component onClick={() => sendMessage('do something')} />
  // <Component sendRequest={sendMessage} />
  return null
}

export default MayaUi
