const mayaInformationMiddleware = (config?: any) => (
  state: any,
  action: any
) => {
  if (
    action?.type === 'mayaMessage' &&
    action?.payload?.type === 'infoPage' &&
    action?.payload?.link
  ) {
    window.open(action?.payload?.link, action?.payload?.target)
  }
}

export default mayaInformationMiddleware
