import React, { useContext } from 'react'
import styles from './styles'
import { UneeqContext } from 'uneeq-react-core'
import { Button } from 'rebass'
import { ReactComponent as FullScreenIcon } from './fullscreen.svg'
import { ReactComponent as FullScreenExitIcon } from './fullscreen_exit.svg'

interface MayaFullscreenButtonProps {
  sx?: any
}

const MayaFullscreenButton = ({ sx = {} }: MayaFullscreenButtonProps) => {
  const { fullscreen, setFullscreen } = useContext(UneeqContext)

  return (
    <Button
      sx={{ ...styles.container, ...sx }}
      variant="unstyled"
      onClick={() => setFullscreen((prev: boolean) => !prev)}
    >
      {fullscreen ? <FullScreenExitIcon /> : <FullScreenIcon />}
    </Button>
  )
}

export default MayaFullscreenButton
