import React, { useEffect } from 'react'
import LoadingVideo from '../../../customPlugins/LoadingVideo/LoadingVideo'
import { StorageKeys } from '../../types/StorageKeys'

const localStorageKey = 'maya_introvideo_played'

type IntroductionVideoProps = {
  onFinishVideo: () => void
}

export const IntroductionVideo = ({
  onFinishVideo
}: IntroductionVideoProps) => {
  useEffect(() => {
    const videoPlayed = localStorage.getItem(StorageKeys.INTROVIDEO_PLAYED)
    if (videoPlayed) {
      onFinishVideo()
    }
  }, [onFinishVideo])

  const handleFinishVideo = () => {
    localStorage.setItem(StorageKeys.INTROVIDEO_PLAYED, 'true')
    onFinishVideo()
  }

  return <LoadingVideo onFinishVideo={handleFinishVideo} forceMp4 />
}
