import React, { useContext } from 'react'
// @ts-ignore
import { Box, Button, Flex, Heading, Text } from 'rebass'
import styles from './styles'
import {
  trackHandler,
  useUneeqState,
  UneeqContext,
  useDisableBodyOverflow
} from 'uneeq-react-core'
import { MayaOverlay } from '../MayaOverlay'
import { useTranslation } from 'react-i18next'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'
import QuestionMark from '../MayaIcons/QuestionMark'
import { useTvContext } from '../../app/hooks/useTvContext'

interface MayaConfirmRestartProps {
  speak: boolean
}

const MayaConfirmRestart = React.memo(({ speak }: MayaConfirmRestartProps) => {
  const { confirmRestartOpen } = useUneeqState()
  const sendMessage = useSendSocketMessage()
  const { tvAppMode, symptomIds } = useTvContext()

  const sendBeginMessage = () => sendMessage({ type: 'begin' })

  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()

  useDisableBodyOverflow(confirmRestartOpen)

  const closeConfirmRestart = () =>
    dispatch({ type: 'mayaCloseConfirmRestart' })
  const handleConfirm = () => {
    if (tvAppMode) {
      sendMessage({
        type: 'begin',
        speak,
        symptomIds:
          Array.isArray(symptomIds) && symptomIds.length !== 0
            ? symptomIds
            : undefined
      })
    } else {
      sendBeginMessage()
    }
    closeConfirmRestart()
  }

  if (!confirmRestartOpen) return null

  return (
    <MayaOverlay>
      <Flex sx={styles.container}>
        <Box sx={{ pt: 8, pb: 12 }}>
          <QuestionMark />
        </Box>
        <Heading variant="title" sx={styles.heading}>
          {t('RestartSession.title')}
        </Heading>
        <Text sx={styles.text}>{t('RestartSession.subtitle')}</Text>
        <Flex sx={styles.buttonsContainer}>
          <Button
            id="confirm"
            variant="alertInverted"
            onClick={trackHandler(handleConfirm, 'exit-session-btn')}
          >
            {t('RestartSession.confirm')}
          </Button>
          <Button
            autoFocus
            id="cancel"
            variant="alertPrimary"
            sx={styles.backToChatButton}
            onClick={closeConfirmRestart}
          >
            {t('RestartSession.dismiss')}
          </Button>
        </Flex>
      </Flex>
    </MayaOverlay>
  )
})

export default MayaConfirmRestart
