import React from 'react'
import { useTranslation } from 'react-i18next'
import { MdSync } from 'react-icons/md'
import { Button } from 'rebass'

interface RestartSessionProps {
  onClick: () => void
}
const RestartSession = ({ onClick }: RestartSessionProps) => {
  const { t } = useTranslation()

  return (
    <Button
      id="restart-session"
      onClick={onClick}
      variant="secondary"
      data-tip={t('Tooltip.restartSessionButton')}
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
          width: '1rem',
          height: '1rem',
          margin: 0
        }
      }}
    >
      <MdSync />
    </Button>
  )
}

export default RestartSession
