import React, { useCallback, useEffect, useState } from 'react'
import { Flex } from 'rebass'
import { useTranslation } from 'react-i18next'
import {
  getPendingTodoList as getTodoListApi,
  completeTodo,
  uncompleteTodo
} from '../api'
import { StorageKeys } from '../types/StorageKeys'
import { User } from '../types/User'
import { Drawer } from './Drawer/Drawer'
import { styles } from './mainMenuStyles'
import { TaskList } from './TaskList'
import { WellnessCheck } from './WellnessCheck'
import { useTvContext } from '../../app/hooks/useTvContext'
import { Todo } from '../types/Todo'

export type Sections =
  | 'wellnessMenu'
  | 'wellness'
  | 'todo-pending'
  | 'todo-completed'
  | 'dashboard'
  | 'find'
  | 'appointment'

type MainMenuProps = {
  onWellnessCheck: () => void
  onLogout: () => void
  setWellnessCheckInTodoId: (id: string) => void
}
export const MainMenu = ({
  onWellnessCheck,
  onLogout,
  setWellnessCheckInTodoId
}: MainMenuProps) => {
  const [collapseDrawer, setCollapseDrawer] = useState(false)
  const [activeSection, setActiveSection] = useState<Sections>('todo-pending')

  const { t } = useTranslation()

  const { setSymptomIds, todoList, setTodoList } = useTvContext()

  useEffect(() => {
    const getTodoList = async () => {
      const user: User = JSON.parse(
        localStorage.getItem(StorageKeys.API_USER) || ''
      )
      const token = localStorage.getItem(StorageKeys.API_TOKEN)

      if (user && token) {
        console.log('HERE')
        try {
          const { response, status } = await getTodoListApi(
            user.patient._id,
            token
          )

          if (status === 'error') {
            onLogout()
          }

          setTodoList(response.result)
        } catch (e) {
          console.log(e)
        }
      }
    }

    getTodoList()

    const interval = setInterval(getTodoList, 10 * 60 * 1000) // 10 minutes
    return () => clearInterval(interval)
  }, [onLogout, setTodoList])

  // if there's a checkin todo, save it for later sending
  // it in the 'begin' message
  useEffect(() => {
    if (todoList) {
      const wellnessCheckInTodo = todoList.find(todo =>
        todo?.todo?.title.includes('Check In')
      )
      if (
        wellnessCheckInTodo?.algorithmId &&
        Array.isArray(wellnessCheckInTodo?.algorithmId) &&
        wellnessCheckInTodo?.algorithmId.length !== 0
      ) {
        setSymptomIds && setSymptomIds(wellnessCheckInTodo.algorithmId)
      }

      // if we have a checkin todo, save the _id so that when we
      // access Wellness Checkin via the Menu, we have the _id too
      if (wellnessCheckInTodo && setWellnessCheckInTodoId) {
        setWellnessCheckInTodoId(wellnessCheckInTodo._id)
      } else {
        setWellnessCheckInTodoId('')
      }
    }
  }, [setSymptomIds, setWellnessCheckInTodoId, todoList])

  const toggleCheck = (checked: boolean, id: string) => {
    const token = localStorage.getItem(StorageKeys.API_TOKEN)
    if (token) {
      try {
        checked ? completeTodo(id, token) : uncompleteTodo(id, token)

        setTodoList(
          todoList &&
            todoList.map(todo => {
              if (todo._id === id) {
                return {
                  ...todo,
                  status: checked ? 'completed' : 'pending'
                }
              }
              return todo
            })
        )
      } catch (e) {}
    }
  }

  const activePendingTodoSection = useCallback(() => {
    setActiveSection('todo-pending')
    setCollapseDrawer(true)
  }, [])

  const activeCompletedTodoSection = useCallback(() => {
    setActiveSection('todo-completed')
    setCollapseDrawer(true)
  }, [])

  return (
    <Flex sx={styles.container}>
      <Drawer
        activeSection={activeSection}
        collapsed={collapseDrawer}
        setCollapsed={setCollapseDrawer}
        setActiveSection={setActiveSection}
        onLogout={onLogout}
      />

      {activeSection.includes('wellness') ? (
        <WellnessCheck
          active={collapseDrawer && activeSection === 'wellness'}
          setActiveSection={() => {
            setActiveSection('wellness')
            setCollapseDrawer(true)
          }}
          onWellnessCheck={onWellnessCheck}
        />
      ) : activeSection.includes('todo') ? (
        <>
          <TaskList
            title={t('lg.dashboard.todoLists.pending.subtitle')}
            subtitle={t('lg.dashboard.todoLists.pending.title')}
            setActive={activePendingTodoSection}
            active={collapseDrawer && activeSection === 'todo-pending'}
            todoList={
              todoList ? todoList.filter(t => t.status === 'pending') : null
            }
            toggleCheck={toggleCheck}
            onWellnessCheck={onWellnessCheck}
            setWellnessCheckInTodoId={setWellnessCheckInTodoId}
            animateRight
            focusableClass="focusable-todo-pending"
          />
          <TaskList
            title={t('lg.dashboard.todoLists.completed.subtitle')}
            subtitle={t('lg.dashboard.todoLists.completed.title')}
            setActive={activeCompletedTodoSection}
            active={collapseDrawer && activeSection === 'todo-completed'}
            todoList={
              todoList ? todoList.filter(t => t.status === 'completed') : null
            }
            toggleCheck={toggleCheck}
            onWellnessCheck={onWellnessCheck}
            focusableClass="focusable-todo-completed"
          />
        </>
      ) : (
        <Flex>Working</Flex>
      )}
    </Flex>
  )
}
