interface mayaTranscriptReducerConfig {
  onOpen?: Object
}

const mayaTranscriptReducer = (config: mayaTranscriptReducerConfig) => {
  const openTranscript = (state: any, open = true) => ({
    ...state,
    ...(open ? config.onOpen : {}),
    transcriptOpen: open,
    transcriptHasOpened: state.transcriptHasOpened || open
  })

  const removeLastMessage = (state: any) => ({
    ...state,
    transcript: state.transcript.slice(0, -1)
  })

  return (state: any, action: any) => {
    switch (action.type) {
      case 'openTranscript':
        return openTranscript(state, action.payload)
      case 'closeTranscript':
        return openTranscript(state, false)
      case 'mayaMessage':
        return handleMayaMessage(state, action.payload)
      case 'mayaError':
        return removeLastMessage(state)
      default:
        return state
    }
  }
}

const jumpedBackIndex = (
  state: any,
  message: any,
  messageType: 'question' | 'infoPage'
) => {
  // check if we already have this question - if so we must have jumped back in history
  const idKey = messageType === 'question' ? 'id' : 'pageId'
  const index = state.transcript.findIndex(
    (item: any) => item.type === messageType && item[idKey] === message[idKey]
  )

  return index
}

const handleMayaMessage = (state: any, message: any) => {
  const appendHistory = (data: any, history = state.transcript) => ({
    ...state,
    transcript: [...history, { ...data, time: new Date() }]
  })
  switch (message.type) {
    case 'question':
    case 'infoPage':
      let history = state.transcript
      const index = jumpedBackIndex(state, message, message.type)

      if (index !== -1) {
        // keep history up until this point
        history = history.slice(0, index)
      }

      return appendHistory(message, history)

    case 'response':
    case 'statement':
      return appendHistory(message)
    default:
      return state
  }
}

export default mayaTranscriptReducer
