export const styles = {
  container: {
    label: 'Login-container',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  card: {
    label: 'Login-card',
    width: '50%',
    bg: 'backgroundPrimary',
    padding: '2rem',
    borderRadius: '8px',
    color: 'white',
    flexDirection: 'column'
  },
  title: {
    label: 'Login-title',
    fontSize: '2rem',
    lineHeight: '1.21',
    textAlign: 'center',
    fontWeight: 700,
    mb: '1rem'
  },
  subtitle: {
    label: 'Login-subtitle',
    fontSize: '0.9rem',
    textAlign: 'center',
    mb: '1rem'
  },
  inputContainer: {
    label: 'Login-inputContainer',
    bg: 'backgroundSecondary',
    boxSizing: 'border-box',
    fontSize: '29px',
    lineHeight: '36px',
    p: '0.5rem',
    mb: '1rem',
    borderRadius: '8px',
    border: '8px solid transparent',
    height: '80px',
    display: 'flex',
    '& > div': {
      width: '100%'
    },
    '&:focus-within': {
      border: '8px solid white'
    }
  },
  inputPassword: {
    outline: 'none',
    border: 'none',
    p: 0,
    fontFamily: 'inherit'
  },
  textError: {
    label: 'Login-textError',
    my: '1rem',
    fontSize: '29px',
    lineHeight: '36px',
    color: 'error',
    bg: 'rgba(254, 232, 232, 0.9)',
    borderRadius: '4px',
    p: '8px'
  },
  buttonsContainer: {
    label: 'Login-buttonsContainer',
    mt: '1.5rem',
    flex: 1,
    '& > *:not(:last-child)': {
      mr: '1rem'
    }
  },
  textInput: {
    label: 'Login-textInput',
    bg: 'backgroundSecondary',
    fontFamily: 'inherit',
    fontSize: ['14px', '14px', '16px', '16px', '16px', '16px'],
    lineHeight: '24px',
    color: 'text',
    border: 'none',
    letterSpacing: '-0.01em',
    width: '100%',
    ':focus': {
      outline: 'none'
    },
    '&::placeholder': {
      opacity: 0.7
    },
    p: 0,
    pl: 2
  },
  codeInputContainer: {
    label: 'Login-codeInputContainer',
    '& input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    margin: '0 auto'
  }
}
