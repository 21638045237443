export const styles = {
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    backgroundImage:
      'radial-gradient(100% 100% at 0% 100%, #102E48 18.49%, #104783 51.19%, #47B8F0 83.14%)'
  },
  informationContainer: {
    label: 'mayaInformation',
    color: 'text',
    minHeight: 329,
    maxHeight: 'calc(100% - 80px)',
    width: 560,
    backgroundColor: 'backgroundPrimary',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backdropFilter: 'blur(50px)',
    borderRadius: 'card',
    padding: 10,
    zIndex: 5
  },
  content: {
    flexDirection: 'column',
    maxHeight: '95%',
    overflow: 'auto',
    position: 'relative'
  },
  buttonsContainer: {
    maxHeight: '80%',
    overflowY: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    mb: 2,
    '& > button': {
      mb: 2
    },
    '& > *:not(:last-child)': {
      mr: 2
    },
    '::-webkit-scrollbar': {
      bg: 'backgroundPrimary',
      height: '8px'
    },
    '::-webkit-scrollbar-thumb': {
      bg: 'text',
      borderRadius: '100px'
    },
    '::-webkit-scrollbar-track': {
      bg: 'backgroundPrimary'
    }
  },
  column: {
    flexDirection: 'column'
  },
  divider: {
    background: '#C4C4C4',
    opacity: 0.2,
    width: '100%',
    height: '1px',
    mb: 8,
    mt: 3
  }
}
