import React from 'react'
import { Text, Flex, Button } from 'rebass'
import styles from './styles'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'
import { TranscriptItem } from './Transcript'
import { stripSsml } from 'uneeq-react-core'
import { ReactComponent as UndoIcon } from './assets/undo.svg'
import { useTvContext } from '../../app/hooks/useTvContext'

interface TranscriptMessageProps {
  item: TranscriptItem
  showUndo: boolean
}
const TranscriptMessage: React.FC<TranscriptMessageProps> = ({
  item,
  showUndo
}) => {
  const sendMessage = useSendSocketMessage()
  const { symptomIds, tvAppMode } = useTvContext()

  switch (item.type) {
    case 'question':
      return (
        <Flex sx={styles.question}>
          {showUndo && (
            <Button
              sx={{
                '&:focus': {
                  border: '1px solid grey'
                }
              }}
              className="focusable"
              variant="buttons.icon"
              onClick={() =>
                sendMessage({
                  type: 'back',
                  id: item.id,
                  symptomIds:
                    Array.isArray(symptomIds) && symptomIds.length !== 0
                      ? symptomIds
                      : undefined
                })
              }
            >
              <UndoIcon />
            </Button>
          )}
          <Text
            className="focusable"
            sx={{
              ...styles.message,
              ...styles.avatarMessage,
              width: tvAppMode ? '100%' : 'auto'
            }}
          >
            {stripSsml(item.question)}
          </Text>
        </Flex>
      )
    case 'response':
      return (
        <Text
          className="focusable"
          sx={{ ...styles.message, ...styles.userMessage }}
        >
          {item.label || item.response}
        </Text>
      )
    case 'infoPage':
      return null
    default:
      return null
  }
}

export default TranscriptMessage
