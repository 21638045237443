import React, { useReducer } from 'react'

type DomainConfigState = {
  disclaimerImage: string
  showLogoOnFlow: boolean
  logo: string
  hideInfoActions: boolean
}

type SetInitialConfig = {
  type: 'SET_INITIAL_CONFIG'
  payload: Partial<DomainConfigState>
}

type SetDisclaimerImageAction = {
  type: 'SET_DISCLAIMER_IMAGE'
  payload: { image: string }
}

export type DomainConfigActions = SetDisclaimerImageAction | SetInitialConfig

export const DomainConfigContext = React.createContext<{
  state: DomainConfigState
  dispatch: React.Dispatch<DomainConfigActions>
}>({ state: {}, dispatch: () => {} } as any)

const reducer = (
  state: DomainConfigState,
  action: DomainConfigActions
): DomainConfigState => {
  switch (action.type) {
    case 'SET_INITIAL_CONFIG': {
      return {
        ...state,
        ...action.payload
      }
    }
    case 'SET_DISCLAIMER_IMAGE': {
      return {
        ...state,
        disclaimerImage: action.payload.image
      }
    }
    default:
      return state
  }
}

export const DomainConfigProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    disclaimerImage: '',
    showLogoOnFlow: false,
    logo: '',
    hideInfoActions: false
  })

  return (
    <DomainConfigContext.Provider value={{ state, dispatch }}>
      {children}
    </DomainConfigContext.Provider>
  )
}
