import { Hospital } from '../types/Hospital'
import { Position } from '../types/Position'

export const getPosition = (h: Hospital | null): Position => {
  if (!h) return {} as Position
  return {
    lat: h.latitude,
    lng: h.longitude
  }
}
