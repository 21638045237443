import { useTheme } from 'emotion-theming'
import React from 'react'

const QuestionMark = () => {
  const theme: any = useTheme()

  const outsideBorderColor = theme.colors.svgIconColor1 || '#4287f5'
  const insideBorderColor = theme.colors.svgIconColor2 || '#4287f5'

  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9219 25.2118C23.9219 22.5202 25.0184 17.9346 31.0994 17.9346C35.9842 17.9346 37.8783 21.0249 37.8783 24.1153C37.8783 27.5047 36.3829 29.2991 30.5013 31.4922V36.9751"
        stroke="currentcolor"
        stroke-width="3"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M30.7006 43.4548C31.6917 43.4548 32.495 42.6515 32.495 41.6604C32.495 40.6694 31.6917 39.866 30.7006 39.866C29.7096 39.866 28.9062 40.6694 28.9062 41.6604C28.9062 42.6515 29.7096 43.4548 30.7006 43.4548Z"
        fill="currentcolor"
      />
      <path
        d="M34.2894 2.08411C48.0464 3.9782 58.7131 15.8411 58.7131 30.1963C58.7131 45.8474 45.9529 58.6075 30.3018 58.6075C14.5511 58.6075 1.89062 45.8474 1.89062 30.1963C1.89062 14.5452 14.5511 1.78505 30.3018 1.78505"
        stroke={outsideBorderColor}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M27.0125 53.9221C15.3489 52.3271 6.37695 42.3583 6.37695 30.1963C6.37695 16.9377 17.0436 6.27103 30.3022 6.27103C43.5608 6.27103 54.2274 16.9377 54.2274 30.1963C54.2274 42.9564 44.1589 53.4237 31.5981 54.1215"
        stroke={insideBorderColor}
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default QuestionMark
