import React, { useEffect } from 'react'
import { useUneeqState } from 'uneeq-react-core'

const MayaDocumentTitle = () => {
  const { documentTitle } = useUneeqState()

  useEffect(() => {
    if (documentTitle) {
      document.title = documentTitle
    }
  }, [documentTitle])

  return null
}

export default MayaDocumentTitle
