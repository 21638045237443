import React, { useCallback, useState } from 'react'
import { Box } from 'rebass'
import { ThemeProvider } from 'emotion-theming'
import { Global } from '@emotion/core'
import { useTranslation } from 'react-i18next'

import theme from '../theme'
import Home from './Home'

import SessionTimedOut from './SessionTimedOut'
import DigitalHuman from './DigitalHuman'
import FatalError from '../customPlugins/MayaErrors/FatalError'
import { styles } from './appStyles'
import { useApplicationState } from '../hooks/useApplicationState'
import { useAutoResize } from '../hooks/useAutoResize'
import { useScrollToTop } from '../hooks/useScrollToTop'
import { ResponsibilityDisclaimerOverlay } from '../customPlugins/ResponsibilityDisclaimerOverlay/ResponsibilityDisclaimerOverlay'
import { DomainConfigProvider } from './contexts/DomainConfigContext'
import LoadingSpinner from '../customPlugins/LoadingSpinner/LoadingSpinner'

const backgroundStyle = {
  label: 'wrapper',
  width: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'right bottom',
  backgroundColor: '#004C9C',
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  left: 0
}

type Steps = 'digitalHuman' | 'timed-out' | 'home' | 'login'

const App = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const embeddedApp = Boolean(queryParams.get('embedded'))
  const timeoutTestTime = Number(queryParams.get('test_timeout_time'))
  // @ts-ignore
  const { t } = useTranslation()

  // For faster testing, skip straight to digitalHuman
  const [step, goTo] = useState<Steps>('home')
  const [speak, setSpeak] = useState(true)
  const {
    currentLanguage,
    disableDH,
    personaId,
    headingMessage,
    initError,
    logo,
    supportedLanguages,
    activeTheme,
    bgChatImage,
    bgImage,
    bgMobileImage,
    hideTranscript,
    hideDownloadNotes,
    loading,
    responsibilityDisclaimer,
    shouldAcceptTerms
  } = useApplicationState({ embeddedApp })

  const restart = () => goTo('home')

  const stepIsDigitalHuman = useCallback(() => step === 'digitalHuman', [step])

  const height = useAutoResize({ condition: stepIsDigitalHuman })
  useScrollToTop({ condition: stepIsDigitalHuman })

  if (loading) {
    return (
      <ThemeProvider theme={theme(activeTheme, embeddedApp)}>
        <Box sx={styles.loadingSpinner}>
          <LoadingSpinner />
        </Box>
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={theme(activeTheme, embeddedApp)}>
      {responsibilityDisclaimer && (
        <ResponsibilityDisclaimerOverlay
          disclaimerImage={
            responsibilityDisclaimer.responsibilityDisclaimerImage
          }
          disclaimerText={responsibilityDisclaimer.responsibilityDisclaimerText}
        />
      )}
      <Global styles={theme => ({ body: { fontFamily: theme.fonts.body } })} />
      <Box
        sx={{
          ...backgroundStyle,
          height: height || '100%',
          overflow: step === 'home' ? 'visible' : 'hidden',
          ...(bgImage && step === 'home'
            ? styles.appBackground(bgImage, bgMobileImage)
            : {}),
          ...(bgChatImage && (step === 'digitalHuman' || step === 'timed-out')
            ? styles.chatBackground(bgChatImage)
            : {})
        }}
      >
        {initError && (
          <FatalError
            errorTitle={t('Error.serverError')}
            errorMessage={initError}
            clearError={() => window.location.reload()}
            buttonText={t('Error.tryAgain')}
          />
        )}
        {step === 'digitalHuman' ? (
          <DigitalHuman
            speak={speak}
            onTimedOut={() => goTo('timed-out')}
            restart={restart}
            onSessionEnded={() => {}}
            embeddedMode={embeddedApp}
            personaId={personaId}
            testTimeoutTime={timeoutTestTime}
            hideTranscript={hideTranscript}
            hideDownloadNotes={hideDownloadNotes}
          />
        ) : step === 'timed-out' ? (
          <SessionTimedOut restart={restart} logo={logo} />
        ) : step === 'home' ? (
          <Home
            embeddedMode={embeddedApp}
            startSession={speak => {
              setSpeak(speak)
              goTo('digitalHuman')
            }}
            restart={() => goTo('home')}
            headingMessage={headingMessage}
            disableDigitalHuman={disableDH}
            logo={logo}
            languageSelected={currentLanguage}
            supportedLanguages={supportedLanguages}
            showVideoBackground={bgImage === null}
            shouldAcceptTerms={shouldAcceptTerms}
          />
        ) : null}
      </Box>
    </ThemeProvider>
  )
}

const AppWrapper = () => {
  return (
    <DomainConfigProvider>
      <App />
    </DomainConfigProvider>
  )
}

export default AppWrapper
