import React from 'react'
import { Flex, Text, Button } from 'rebass'
import { useTranslation } from 'react-i18next'
import { styles } from './wellnessCheckStyles'
import { ReactComponent as ArrowRight } from './arrow-right.svg'
import { useTvContext } from '../../../app/hooks/useTvContext'

type WellnessCheckProps = {
  onWellnessCheck: () => void
  setActiveSection: () => void
  active: boolean
}
export const WellnessCheck = ({
  onWellnessCheck,
  active,
  setActiveSection
}: WellnessCheckProps) => {
  const { t } = useTranslation()

  const { wellnessCheckInTodoId } = useTvContext()

  return (
    <Flex onClick={setActiveSection} sx={styles.container(active)}>
      <Text sx={styles.font}>
        {!wellnessCheckInTodoId
          ? t('lg.dashboard.wellnessCheck.alreadyCompleted')
          : t('lg.dashboard.wellnessCheck.title')}
      </Text>
      {wellnessCheckInTodoId && (
        <Button
          className="focusable-wellness-menu"
          onFocus={() => setActiveSection()}
          onClick={onWellnessCheck}
          variant="unstyled"
          sx={{ ...styles.font, ...styles.button }}
        >
          {t('lg.dashboard.wellnessCheck.start')}
          <ArrowRight />
        </Button>
      )}
    </Flex>
  )
}
