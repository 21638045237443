import React from 'react'
import { SxStyleProp } from 'rebass'
import { Overlay } from 'uneeq-react-ui'
import useWidgetContext from '../../app/hooks/useWidgetContext'

interface MayaOverlayProps {
  transparent?: boolean
  children: React.ReactNode
  sx?: SxStyleProp
}
const MayaOverlay = ({ children, sx, ...props }: MayaOverlayProps) => {
  const { widgetMode, fullscreen } = useWidgetContext()
  return (
    <Overlay
      {...props}
      sx={{
        ...sx,
        backgroundColor: 'overlayBackground',
        ...(widgetMode
          ? {
              position: 'fixed',
              width: ['100%', '100%', '100%', '100%', 568, 568],
              right: 0,
              left: fullscreen ? '50%' : 'auto',
              ml: fullscreen
                ? ['-50%', '-50%', '-50%', '-50%', -284, -284]
                : 'auto',
              zIndex: 999999,
              backdropFilter: 'blur(5px)',
              webkitBackdropFilter: 'blur(5px)'
            }
          : {})
      }}
    >
      {children}
    </Overlay>
  )
}

export default MayaOverlay
