import React, { useContext } from 'react'
import { Text, Button } from 'rebass'
import { trackHandler, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { ReactComponent as BackIcon } from '../../assets/img/back.svg'
import styles from './styles'
import { useTranslation } from 'react-i18next'

const BackButton = () => {
  const { dispatch } = useContext(UneeqContext)
  const { previousSuggestedResponses, onScreenInfo } = useUneeqState()
  const { t } = useTranslation()

  if (previousSuggestedResponses.length === 0) return null

  const back = () => {
    dispatch({ type: 'previousSuggestedResponses', payload: true })
  }

  return (
    <Button
      sx={styles.button}
      variant="primary"
      onClick={trackHandler(back, 'back-btn')}
    >
      <BackIcon />
      <Text as="span" sx={styles.text}>
        {t('BackButton.back')}
      </Text>
    </Button>
  )
}

export default BackButton
