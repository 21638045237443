import { ErrorLevel, stripSsml } from 'uneeq-react-core'

const mayaErrorsReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'mayaError':
      return { ...state, questionLoading: false, error: { ...action.payload, level: ErrorLevel.ERROR} }
    default:
      return state
  }
}

export default mayaErrorsReducer
