export const styles = {
  container: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    alignSelf: 'center',
    width: ['95%', '95%', 560, 560, 560, 560],
    zIndex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: 'backgroundPrimary',
    padding: 12,
    paddingTop: 13,
    borderRadius: 'card',
    backdropFilter: 'blur(100px)',
    color: 'text'
  },
  heading: {
    display: 'inline-flex',
    width: 'fit-content',
    fontSize: 5,
    mb: 12,
    textAlign: 'center'
  },
  paragraph: {
    m: 0,
    mb: 3,
    fontSize: 3,
    textAlign: 'center'
  },
  requirementList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginBottom: '16px'
  },
  requirement: {
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'rgba(51, 51, 51, 0.1)',
    paddingTop: '12px',
    paddingBottom: '12px',
    paddingLeft: '16px',
    paddingRight: '16px',
    width: '100%',
    margin: '6px'
  },
  browserIcons: {
    mt: 3,
    justifyContent: 'center'
  },
  buttons: {
    mt: 10,
    justifyContent: 'center',
    flexDirection: [
      'column-reverse',
      'column-reverse',
      'column-reverse',
      'column-reverse',
      'row',
      'row'
    ],
    alignItems: 'center',
    '& > *:not(:last-child)': {
      mr: [0, 0, 0, 0, 3, 3],
      mt: [3, 3, 3, 3, 0, 0]
    }
  },
  backToHomeButton: {
    mr: 3,
    mt: [5, 5, 5, 5, 0, 0]
  }
}
export default styles
