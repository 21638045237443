const pauseSessionMiddleware = (config?: any) => (
  state: any,
  action: any,
  uneeq: any
) => {
  if (action?.type === 'pauseSession') {
    uneeq.pauseSession()
  }
  if (action?.type === 'resumeSession') {
    uneeq.resumeSession()
  }
  if (action?.type === 'initSession') {
    uneeq.initWithToken(state.mayaConfig.token)
  }
}

export default pauseSessionMiddleware
