import { Uneeq } from 'uneeq-js'

// Send any questions to UneeQ echo backend to be spoken
const mayaEchoMiddleware = () => (state: any, action: any, uneeq: Uneeq) => {
  if (
    action.type === 'mayaMessage' &&
    action.payload.type === 'question' &&
    !action.payload.mute &&
    !state.loadingVideoActive
  ) {
    const speak = action.payload.speak || action.payload.question
    !state.sessionPaused && uneeq?.sendTranscript(speak)
  }
  if (
    action.type === 'mayaMessage' &&
    action.payload.type === 'infoPage' &&
    action.payload.ssml &&
    !action.payload.mute
  ) {
    !state.sessionPaused && uneeq?.sendTranscript(action.payload.ssml)
  }
}

export default mayaEchoMiddleware
