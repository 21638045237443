import { useUneeqState, UneeqContext } from 'uneeq-react-core'
import { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

const useAnswerQuestionTimeout = (timeout: number) => {
  const { questionLoading } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()

  useEffect(() => {
    if (questionLoading) {
      // The user send the response
      const clearLoadingIndicatorTimer = setTimeout(() => {
        dispatch({
          type: 'mayaError',
          payload: { message: t('Error.networkTimeout') }
        })
      }, timeout)

      return () => clearTimeout(clearLoadingIndicatorTimer)
    }
  }, [questionLoading, dispatch, timeout, t])
}

export default useAnswerQuestionTimeout
