import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Text } from 'rebass'
import { styles } from './loginStyles'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import { E164Number } from 'libphonenumber-js/types'
import 'react-phone-number-input/style.css'
import { Input } from '@rebass/forms'
import { login as loginApi } from '../api'
import { User } from '../types/User'
import { StorageKeys } from '../types/StorageKeys'
import { useTranslation } from 'react-i18next'

type LoginProps = {
  onSuccess: () => void
}
export const Login = ({ onSuccess }: LoginProps) => {
  const [checkingAuthenticatedUser, setCheckingAuthenticatedUser] = useState(
    true
  )
  const [loading, setLoading] = useState(false)
  const [phone, setPhone] = useState<E164Number | undefined>(undefined)
  const [password, setPassword] = useState<string>('')
  const [error, setError] = useState('')

  const { t } = useTranslation()

  const isDeviceAuthenticated = async () => {
    // Some api call to refresh token??
    const user: User = JSON.parse(
      localStorage.getItem(StorageKeys.API_USER) || '{}'
    )
    const token = localStorage.getItem(StorageKeys.API_TOKEN)

    return user && token
  }

  useEffect(() => {
    const checkAuthenticatedUser = async () => {
      const deviceId = 'lgtv' // webOS.deviceInfo or smt like that
      const isAuthenticated = await isDeviceAuthenticated()
      if (isAuthenticated) {
        return onSuccess()
      }

      setCheckingAuthenticatedUser(false)
    }

    checkAuthenticatedUser()
  }, [onSuccess])

  if (checkingAuthenticatedUser) {
    return <Flex>{t('lg.login.loading')}</Flex>
  }

  const login = async () => {
    setError('')
    if (!phone || !password) {
      return setError(t('lg.login.missingLoginInfo'))
    }

    if (!isPossiblePhoneNumber(phone.toString())) {
      return setError(t('lg.login.invalidPhoneNumber'))
    }

    setLoading(true)
    try {
      const { response, status } = await loginApi(phone.toString(), password)
      console.log(response)

      if (status === 'error') {
        return setError(t('lg.login.invalidLoginInfo'))
      }

      if (response.result.token) {
        localStorage.setItem(StorageKeys.API_TOKEN, response.result.token)
        localStorage.setItem(
          StorageKeys.API_USER,
          JSON.stringify(response.result.user)
        )
        onSuccess()
      }
    } catch (e) {
      setError(t('lg.login.loginError'))
    } finally {
      setLoading(false)
    }
  }

  const submitOnEnter = (
    e: React.KeyboardEvent<HTMLInputElement>,
    submit: any
  ) => {
    if (e.code === 'Enter') {
      submit(e)
    }
  }

  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.card}>
        <Text sx={styles.title}>{t('lg.login.title')}</Text>
        <Box sx={styles.inputContainer}>
          <PhoneInput
            placeholder={t('lg.login.phonePlaceholder')}
            value={phone}
            onChange={setPhone}
            autoComplete="off"
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              submitOnEnter(e, login)
            }
          />
        </Box>
        <Box sx={styles.inputContainer}>
          <Input
            sx={styles.inputPassword}
            name="password"
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder={t('lg.login.passwordPlaceholder')}
            autoComplete="off"
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) =>
              submitOnEnter(e, login)
            }
          />
        </Box>
        {error && <Text sx={styles.textError}>{error}</Text>}

        <Button
          sx={{
            height: '80px',
            fontSize: '29px',
            lineHeight: '36px',
            fontWeight: 700,
            color: 'white',
            bg: 'primary',
            cursor: 'pointer'
          }}
          variant="unstyled"
          disabled={loading}
          onClick={login}
        >
          {t('lg.login.loginButton')}
        </Button>
      </Flex>
    </Flex>
  )
}
