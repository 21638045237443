import React from 'react'
import { Box, Image } from 'rebass'
import sophie from '../../app/assets/img/sophie-squared.png'

const ChatButton = ({
  handleClick,
  showChat,
  children,
  fullScreen,
  height
}: any) => {
  const desktopContainerHeight = height ? `calc(${height}px - 100px)` : 'auto'

  return (
    <Box
      sx={
        fullScreen
          ? {
              height: height || '100vh',
              width: '100vw',
              position: 'fixed',
              bottom: 0,
              left: 0,
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              bg: 'rgba(0,0,0,0.2)',
              backdropFilter: 'blur(5px)',
              overflow: 'hidden',
              zIndex: 999999
            }
          : {
              label: 'widgetZIndex',
              position: 'fixed',
              zIndex: 999999,
              bottom: 0,
              right: 0,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              width: ['100%', '100%', '100%', '100%', 560, 560]
            }
      }
    >
      <Box
        sx={{
          width: ['100%', '100%', '100%', '100%', 560, 560],
          height: [
            '100%',
            '100%',
            '100%',
            '100%',
            desktopContainerHeight,
            desktopContainerHeight
          ],
          marginBottom: fullScreen ? '0' : '16px',
          overflow: fullScreen ? 'hidden' : 'unset',
          display: 'flex',
          alignItems: 'flex-end',
          ...(!showChat ? { display: 'none' } : {})
        }}
      >
        {children}
      </Box>

      <Box
        onClick={() => {
          handleClick()
        }}
        sx={{
          display: fullScreen ? 'none' : 'block',
          cursor: 'pointer',
          borderRadius: '100%',
          bg: 'transparent',
          fontSize: 6,
          width: 'fit-content',
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            width: '120px',
            height: '120px',
            bg: 'backgroundPrimary'
          }}
        >
          <Image
            sx={{ width: '100%', transform: 'translateY(5px)' }}
            src={sophie}
            alt="Toggle chat"
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ChatButton
