import React from 'react'
import { Text, Button } from 'rebass'
import { trackHandler } from 'uneeq-react-core'
import { ReactComponent as LeaveChatIcon } from '../../../assets/img/leave-chat-icon.svg'
import styles from './styles'
import { useTranslation } from 'react-i18next'

interface EndSessionButtonProps {
  restart: () => void
}

const EndSessionButton: React.FC<EndSessionButtonProps> = ({ restart }) => {
  const { t } = useTranslation()

  return (
    <Button
      sx={styles.button}
      variant="primaryInverted"
      onClick={trackHandler(restart, 'exit-session-btn')}
    >
      <LeaveChatIcon />
      <Text as="span" sx={styles.text}>
        {t('endSession.EndSessionButton.leaveChat')}
      </Text>
    </Button>
  )
}

export default EndSessionButton
