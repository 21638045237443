import React from 'react'
import { FaDirections } from 'react-icons/fa'
import { Button } from 'rebass'
import { Position } from '../types/Position'
import styles from '../styles'

const googleMapsAPIUrl = 'https://www.google.com/maps/dir/?api=1'

const openMap = (url: string) => {
  window.open(url, '_blank')
}

interface DirectionsButtonProps {
  origin: Position
  destination: Position
}
const DirectionsButton = ({ origin, destination }: DirectionsButtonProps) => {
  const googleMapUrl = `${googleMapsAPIUrl}&origin=${origin.lat},${origin.lng}&destination=${destination.lat},${destination.lng}&travelmode=driving`

  return (
    <Button
      sx={{ ...styles.iconButton, ...styles.directionsButton }}
      onClick={() => openMap(googleMapUrl)}
    >
      <FaDirections />
    </Button>
  )
}

export default DirectionsButton
