import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { stripSsml, UneeqContext, useUneeqState } from 'uneeq-react-core'
import { useSendSocketMessage } from '../../../app/hooks/useSendSocketMessage'
import { Box, Button, Flex, Text } from 'rebass'
import styles from '../styles'
import useWidgetContext from '../../../app/hooks/useWidgetContext'
import { markdownQuestion } from '../Question'
import { fnOnEnter } from '../../../utils'

const SelectQuestion = () => {
  const { dispatch } = useContext(UneeqContext)
  const { t } = useTranslation()
  const sendMessage = useSendSocketMessage()
  const { mayaQuestion, hideQuestionTitle } = useUneeqState()
  const { widgetMode, fullscreen } = useWidgetContext()

  // For style only
  const [selectedAnswer, setSelectedAnswer] = useState(-1)

  useEffect(() => {
    // Backend tell us which option should be pre-selected
    if (mayaQuestion.value) {
      setSelectedAnswer(mayaQuestion.value)
    }
  }, [mayaQuestion])

  const questionText = useMemo(
    () => markdownQuestion(stripSsml(mayaQuestion.question)),
    [mayaQuestion]
  )
  const submitOption = (opt: any) => {
    setSelectedAnswer(opt.id)
    const info = {
      type: 'response',
      questionId: mayaQuestion.id,
      response: opt.id ? opt.id.toString() : '',
      label: opt.label ? opt.label : t('Transcript.skippedQuestion')
    }
    dispatch({ type: 'mayaMessage', payload: info })
    sendMessage(info)
  }

  return (
    <Flex sx={styles.selectQuestionContainer}>
      <Flex sx={styles.topContainer}>
        {!hideQuestionTitle && <Text sx={styles.question}>{questionText}</Text>}
        <Text sx={styles.instructions}>{mayaQuestion.instruction}</Text>
      </Flex>
      <Box
        sx={{
          ...styles.multiSelectOptionsContainer,
          ...(widgetMode && !fullscreen
            ? styles.widgetMultiSelectOptionsContainer
            : {})
        }}
      >
        {mayaQuestion.options.map((option: any) => (
          <Button
            variant="select"
            key={option.id}
            onMouseDown={() => submitOption(option)}
            onKeyPress={fnOnEnter(() => submitOption(option))}
            sx={
              selectedAnswer === option.id
                ? {
                    borderColor: 'optionSelectedBorder',
                    bg: 'optionSelectedBg'
                  }
                : {
                    borderColor: 'optionBorder'
                  }
            }
          >
            {option.label}
          </Button>
        ))}
      </Box>

      {mayaQuestion.optional && (
        <Button
          type="submit"
          onClick={() => submitOption('')}
          sx={{ ...styles.button, ...styles.multiSelectSubmitButton }}
        >
          {t('Question.skip')}
        </Button>
      )}
    </Flex>
  )
}

export default SelectQuestion
