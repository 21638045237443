import React, { useContext, useState } from 'react'
import styles from './styles'
import { Text, Flex, Button } from 'rebass'
import { useTranslation } from 'react-i18next'
import { useUneeqState, UneeqContext } from 'uneeq-react-core'

const MayaBandwidth = () => {
  const { t } = useTranslation()
  const { isLowBandwidth, finishLoadingAt } = useUneeqState()
  const { dispatch } = useContext(UneeqContext)
  const [showAlert, setShowAlert] = useState(true)

  const pauseDigitalHuman = () => {
    dispatch({ type: 'pauseSession' })
    setShowAlert(false)
  }

  const dismiss = () => {
    setShowAlert(false)
  }

  if (!showAlert) {
    return null
  }

  if (!finishLoadingAt) {
    return null
  }

  const Alert = () => (
    <Flex sx={styles.container}>
      <Text>{t('Bandwidth.title')}</Text>
      <Flex sx={styles.actions}>
        <Button variant="primary" onClick={pauseDigitalHuman}>
          {t('Bandwidth.confirm')}
        </Button>
        <Button variant="whiteInverted" onClick={dismiss}>
          {t('Bandwidth.dismiss')}
        </Button>
      </Flex>
    </Flex>
  )

  return isLowBandwidth ? <Alert /> : null
}

export default MayaBandwidth
