import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import styles from './styles'
import { trackHandler, useTranscript } from 'uneeq-react-core'
import { Button } from 'rebass'
import { ReactComponent as BackIcon } from './back.svg'
import { useSendSocketMessage } from '../../app/hooks/useSendSocketMessage'

interface MayaBackButtonProps {
  onClick?: () => any
  sx?: any
}

const MayaBackButton = ({ onClick, sx = {} }: MayaBackButtonProps) => {
  const { transcript } = useTranscript()
  const { t } = useTranslation()
  const sendMessage = useSendSocketMessage()
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const back = () => {
    if (onClick) {
      onClick()
    }
    sendMessage({ type: 'back' })
  }
  if (transcript.length < 2) return <div>&nbsp;</div>

  return (
    <>
      <Button
        id="back"
        variant="icon"
        data-tip={t('Tooltip.backButton')}
        sx={{ ...styles.container, ...sx, position: 'relative' }}
        onClick={trackHandler(back, 'back-btn')}
      >
        <BackIcon />
      </Button>
    </>
  )
}

export default MayaBackButton
