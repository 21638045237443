const styles = {
  container: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    alignSelf: 'center',
    width: ['95%', '95%', 560, 560, 560, 560],
    zIndex: 1,
    flexDirection: 'column',
    p: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'backgroundPrimary',
    borderRadius: 'card',
    backdropFilter: 'blur(100px)',
    color: 'text'
  },
  heading: {
    fontSize: 5,
    fontWeight: 'bold'
  }
}

export default styles
