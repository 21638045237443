export const styles = {
  container: {
    position: 'absolute',
    maxHeight: '90%',
    alignSelf: 'center',
    width: ['95%', '95%', '55%', '55%', '55%', '55%'],
    zIndex: 1,
    flexDirection: 'column',
    p: 10,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'backgroundPrimary',
    borderRadius: 'card',
    backdropFilter: 'blur(100px)',
    color: 'text'
  },
  heading: {
    fontSize: [5, 5, 5, 5, 48, 48],
    fontWeight: ['normal', 'normal', 'normal', 'normal', 'normal', 'bold'],
    mb: 2,
    lineHeight: 1,
    pr: 10
  },
  text: {
    fontSize: 1,
    color: 'text',
    mb: 5,
    overflow: 'scroll',
    '& a, & a:active': {
      color: 'primaryLight',
      textDecoration: 'none'
    },
    '& a:hover': {
      textDecoration: 'underline'
    }
  },
  header: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between'
  },
  icon: {
    maxHeight: 33,
    maxWidth: 33,
    minHeight: 33,
    minWidth: 33,
    '& svg': {
      width: 14,
      height: 14,
      strokeWidth: 0
    }
  }
}

export default styles
